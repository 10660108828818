import request from '@/util/request';
// 职业认证 -- 我要认证/新建认证（确认）：
export function createProfessionalApply(data) {
  return request({
    url: "/api/business/qualificationAuthentication",
    method: 'POST',
    data
  })
}

// 职业认证 --  我要认证/新建认证 -- 认证公司/单位/协会;
export function getCertUnitList(params,id) {
  return request({
    url: `/api/system/Permission/Organize/getParentId/${id}`,
    method: 'get',
    params
  })
}

// 职业认证 -- 认证修改数据和履历（确认）：
export function qualificationAuthentication(data) {
  return request({
    url: "/api/business/qualificationAuthentication",
    method: 'PUT',
    data
  })
}

// 职业认证 -- 认证履历（删除）id：
export function recordDeleteId(data,id) {
  return request({
    url: `/api/business/qualificationRecord/deleteId/${id}`,
    method: 'PUT',
    data
  })
}

// 职业认证 -- 我要认证：赛事/体育项目-查询所有项目 （itemType：竞技：1，群众：2，公用：3）
export function getBaseItemList(params) {
  return request({
    url: "/api/competitionUniversal/competitionBaseItem/getBaseItemList",
    method: 'get',
    params
  })
}


// 职业认证 -- 列表 userId，tenantId
export function getList(data) {
  return request({
    url: `/api/business/qualificationAuthentication/selectAllUserId`,
    method: 'post',
    data
  })
}

// 职业认证 -- 修改数据 : 是否公开：（"id": "",	"isopen": true,）
export function isPublic(data) {
  return request({
    url: `/api/business/qualificationAuthentication/updateId`,
    method: 'PUT',
    data
  })
}

// 职业认证 -- 认证id查询详情 
export function getDetail(params, id) {
  return request({
    url: `/api/business/qualificationAuthentication/${id}`,
    method: 'GET',
    params
  })
}

// 职业认证 -- 绑定单位（获取单位列表）：获取组织列表
export function getOrganize(params) {
  return request({
    url: `/api/system/Permission/Organize`,
    method: 'GET',
    params
  })
}

// 职业认证 -- 绑定单位（获取单位列表）：获取组织列表
export function getUnitList(params,fullName,tid) {
  return request({
    url: `/api/system/Permission/Organize/getfullName/${fullName}/${tid}`,
    method: 'GET',
    params
  })
}

// 职业认证 -- 绑定单位:确认绑定（按钮）
export function bindUnit(data) {
  return request({
    url: `/api/business/bindUnit`,
    method: 'post',
    data
  })
}

// 职业认证 -- 绑定单位:解绑单位-- 解绑（按钮）
// /api/business/bindUnit/{id}
export function unBindUnit(data) {
  return request({
    url: `/api/business/bindUnit/untie`,
    method: 'put',
    data
  })
}

// 职业认证 -- 查询当前人绑定的公司-官网：显示已绑定单位（卡片详情）
export function getBindUnited(params,qid) {
  return request({
    url: `/api/business/bindUnit/uid/${qid}`,
    method: 'GET',
    params
  })
}

// 职业认证 -- 我绑定单位数据
export function myQueryMeBindUnit(data) {
  return request({
    url: `/api/business/bindUnit/queryMeBindUnit`,
    method: 'POST',
    data
  })
}

// 职业认证 -- 单位申请职业认证查询全部分页
export function unitApplyList(data) {
  return request({
    url: `/api/business/unitQualification/getList`,
    method: 'POST',
    data
  })
}

// 职业认证 -- 单位向我申请（0：申请中，1：申请成功，2：申请失败 3：未申请)
export function unitQualification(data) {
  return request({
    url: `/api/business/unitQualification`,
    method: 'put',
    data
  })
}

// 职业认证 -- 人员信息表(教练员，裁判员，运动员等)新增或修改数据
export function recordInformation(data) {
  return request({
    url: `/api/business/recordInformation`,
    method: 'POST',
    data
  })
}

// 职业认证 -- 人员信息表(教练员，裁判员，运动员等) 查询:是否存在
export function isRecordInformation(params,qid) {
  return request({
    url: `/api/business/recordInformation/selectOneQid/${qid}`,
    method: 'GET',
    params
  })
}

// 职业认证 -- 人员信息表(教练员，裁判员，运动员等) 查询
export function getRecordInformation(params,id) {
  return request({
    url: `/api/business/recordInformation/${id}`,
    method: 'GET',
    params
  })
}


// 职业认证 -- 人员信息表 -- 认证履历学历信息（删除）id;
export function delRecordEducation(data,id) {
  return request({
    url: `/api/business/recordEducation/deleteId/${id}`,
    method: 'put',
    data
  })
}

// 职业认证 -- 人员信息表 -- 认证履历岗位信息（删除）id;
export function delRecordPosition(data,id) {
  return request({
    url: `/api/business/recordPosition/deleteId/${id}`,
    method: 'put',
    data
  })
}

// 职业认证 -- 人员信息表 -- 认证履历工作经历（删除）id;
export function delRecordWorkExperience(data,id) {
  return request({
    url: `/api/business/recordWorkExperience/deleteId/${id}`,
    method: 'put',
    data
  })
}

// 职业认证 -- 人员信息表 -- 认证履历职称晋升（删除）id;
export function delRecordPromote(data,id) {
  return request({
    url: `/api/business/recordPromote/deleteId/${id}`,
    method: 'put',
    data
  })
}

// 职业认证 -- 人员信息表 --  认证履历论文信息（删除）id;
export function delRecordPaper(data,id) {
  return request({
    url: `/api/business/recordPaper/deleteId/${id}`,
    method: 'put',
    data
  })
}


// 20220308 职业认证 --  查询人员动态模板 -- 根据租户项目和人员类型查询模板{  itemId，professionType，tenantId}
export function getProfessionName(data) {
  return request({
    url: `/api/business/personnelRegistrationForm/getProfessionName`,
    method: 'POST',
    data
  })
}







