<template>
  <div class="professional-info">
    <div class="professional-info-title">
      {{ cpitem.careerName ? "修改" : "新建" }}认证信息表
      <el-button @click="showPIList"> 返回列表</el-button>
    </div>
    <div class="professional-info-wrap">
      <div class="scrollbar">
        <!-- <el-scrollbar></el-scrollbar> -->
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="professional-ruleForm">
          <el-form-item label="认证单位" prop="belowTenantName">
            <el-select v-model="ruleForm.belowTenantName" filterable allow-create default-first-option placeholder="请选择" @change="selecteCertUnit">
              <el-option v-for="item in certUnitList" :key="item.id" :label="item.fullName" :value="item.fullName"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="职位名称" prop="careerId">
            <el-select v-model="ruleForm.careerId" filterable allow-create default-first-option placeholder="请选择" @change="selecteDictItemCareerName($event, 'careerId')"
                       @focus=" getDictCareerName( 'dictCareerCategory', 'f6ba8b07fbae4a2daa22177df2588c4e' )">
              <el-option v-for="item in dictCareerCategory" :key="item.id" :label="item.fullName" :value="item.enCode"
                         :disabled=" !( item.fullName == '裁判员' || item.fullName == '教练员' || item.fullName == '工作人员' )"></el-option>
            </el-select>
          </el-form-item>

          <template v-if="isShowCI">
            <el-form-item class="form-item-title" label="证书信息"></el-form-item>
            <el-form-item label="体育项目" prop="itemName">
              <el-select v-model="ruleForm.itemName" placeholder="请选择项目" filterable allow-create default-first-option @change="funItems" @focus="getBaseItemLists">
                <el-option v-for="item in options" :key="item.id" :label="item.itemName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="级别 " prop="grade">
              <el-select v-model="ruleForm.grade" filterable allow-create default-first-option placeholder="请选择" @change="selecteCareerLevel">
                <el-option v-for="item in dictCareerLevel" :key="item.id" :label="item.fullName" :value="item.fullName"></el-option>
              </el-select>
              <span class="tips-text">( ps：先选职业名称 )</span>
            </el-form-item>
            <el-form-item label="证书名称" prop="careerCertificate">
              <el-input type="text" v-model="ruleForm.careerCertificate" placeholder="请输入证书名称"></el-input>
            </el-form-item>
            <el-form-item label="证书编号" prop="certificateNumber">
              <el-input type="text" v-model="ruleForm.certificateNumber" placeholder="请输入证书编号"></el-input>
            </el-form-item>
            <el-form-item label="获取时间" prop="getTime">
              <el-date-picker v-model="ruleForm.getTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item label="上传证书附件" prop="certificatePhoto">
              <el-upload :action="uploadImg()" :headers="uploadHeaders()" list-type="picture-card" :file-list="fileList" :on-success="handleAvatarSuccess" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="tips-text">上传照片格式要求，jpg、jpeg、bmp、png，不超过10m。</div>
            </el-form-item>
          </template>

          <el-form-item class="form-item-title" label="基本信息"></el-form-item>

          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" readonly placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="籍贯" prop="natives">
            <el-input v-model="ruleForm.natives" placeholder="请输入籍贯"></el-input>
          </el-form-item>
          <el-form-item label="性别" required prop="sex">
            <el-radio-group v-model="ruleForm.sex" disabled>
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthDate">
            <el-input readonly v-model="ruleForm.birthDate" placeholder="请输入出生日期"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="identityCard">
            <el-input readonly v-model="ruleForm.identityCard" placeholder="请输入身份证号码"></el-input>
          </el-form-item>
          <el-form-item label="身份证住址" prop="identityAddress">
            <el-input v-model="ruleForm.identityAddress" placeholder="请输入身份证住址"></el-input>
          </el-form-item>
          <el-form-item label="民族" prop="nation">
            <el-input v-model="ruleForm.nation" placeholder="请输入民族"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input readonly v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item class="form-item-title" label="绑定单位信息"></el-form-item>

          <el-form-item v-for="item in customForms" :key="item.formId" :label="item.label" :prop="item.vModel" :required="item.required">
            <template v-if="item.tagIcon === 'radio'">
              <el-radio-group v-model="ruleForm[item.vModel]" :placeholder="item.placeholder" :value="item.defaultValue">
                <el-radio v-for="iRadio in item.options" :key="iRadio.value" :value="iRadio.value" :label="iRadio.label"></el-radio>
              </el-radio-group>
            </template>
            <template v-if="item.tagIcon === 'select'">
              <el-select :class="{ noValid: !item.required }" v-model="ruleForm[item.vModel]" :value="item.defaultValue" :placeholder="item.placeholder"
                         :rules="[ { required: item.required, message: item.placeholder, trigger: 'change', }, { pattern: [...item.regList].pattern, message: [...item.regList].message, }, ]">
                <el-option v-for="iSelect in item.options" :key="iSelect.name" :name="iSelect.name" :value="iSelect.value" :label="iSelect.name"></el-option>
              </el-select>
            </template>
            <template v-if="item.tagIcon === 'input'">
              <el-input :class="{ noValid: !item.required }" v-model="ruleForm[item.vModel]" type="text" :label="item.label" :maxlength="item.maxlength"
                        :readonly="item.readonly" :placeholder="item.placeholder" :value="item.defaultValue" :rules="{ required: item.required, message: item.placeholder, trigger: 'blur', }"></el-input>
            </template>
            <template v-if="item.tagIcon === 'textarea'">
              <el-input :class="{ noValid: !item.required }" v-model="ruleForm[item.vModel]" type="textarea" :label="item.label" :maxlength="item.maxlength"
                        :readonly="item.readonly" :placeholder="item.placeholder" :value="item.defaultValue" :rules="[ { required: item.required, message: item.placeholder, trigger: 'blur', }, { pattern: [...item.regList].pattern, message: [...item.regList].message, }, ]"></el-input>
            </template>

            <template v-if="item.tagIcon === 'upload'">
              <el-upload :class="{ noValid: !item.required }" :action="item.action" list-type="picture-card" :file-list="cfileList" :multiple="item.multiple" :limit="item.fileSize" :on-success="cSuccess" :on-preview="cPreview" :on-remove="cRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="tips-text">上传照片格式要求，jpg、jpeg、bmp、png，不超过10m。</div>
            </template>
          </el-form-item>

          <el-form-item class="form-item-title" label="学历（学位）教育情况"></el-form-item>
          <template v-for="(reTime, index) in recordEducations">
            <template v-if="index == 0 || recordEducations.length == 0">
              <el-form-item label="起始时间" :key="'renewtime-' + index">
                <el-date-picker v-model="reTime.newCRtime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                <el-button type="text" class="btn-delCertResume" @click="delRecordEducations(reTime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="毕业院校" :key="'RESG-' + index">
                <el-input v-model="reTime.schoolGraduation" placeholder="请输入毕业院校"></el-input>
              </el-form-item>
              <el-form-item label="专业" :key="'REST-' + index">
                <el-input v-model="reTime.specialty" placeholder="请输入专业"></el-input>
              </el-form-item>
              <el-form-item label="学制（年）" :key="'RES-' + index">
                <el-input v-model="reTime.schooling" placeholder="请输入学制（年）"></el-input>
              </el-form-item>
              <el-form-item label="学历（学位）" :key="'REE-' + index">
                <el-input v-model="reTime.education" placeholder="请输入学历（学位）"></el-input>
              </el-form-item>
              <el-form-item label="办学形式" :key="'RERF-' + index">
                <el-select v-model="reTime.runningForms" placeholder="请选择办学形式" filterable allow-create default-first-option @change="selecteDictItem($event, 'runningForms')"
                           @focus=" getDict( 'dictRunningForms', '988089a029364cd2a3582c2c638145d0' )">
                  <el-option v-for="item in dictRunningForms" :key="item.id" :label="item.fullName" :value="item.enCode"></el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item :key="'REdu--' + index">
                <el-divider></el-divider>
              </el-form-item>
              <el-form-item label="起始时间" :key="'renewtime-' + index">
                <el-date-picker v-model="reTime.newCRtime" type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                <el-button type="text" class="btn-delCertResume" @click="delRecordEducations(reTime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="毕业院校" :key="'RESG-' + index">
                <el-input v-model="reTime.schoolGraduation" placeholder="请输入毕业院校"></el-input>
              </el-form-item>
              <el-form-item label="专业" :key="'REST-' + index">
                <el-input v-model="reTime.specialty" placeholder="请输入专业"></el-input>
              </el-form-item>
              <el-form-item label="学制（年）" :key="'RES-' + index">
                <el-input v-model="reTime.schooling" placeholder="请输入学制（年）"></el-input>
              </el-form-item>
              <el-form-item label="学历（学位）" :key="'REE-' + index">
                <el-input
                  v-model="reTime.education" placeholder="请输入学历（学位）"></el-input>
              </el-form-item>
              <el-form-item label="办学形式" :key="'RERF-' + index">
                <el-select v-model="reTime.runningForms" placeholder="请选择办学形式" filterable allow-create default-first-option
                           @change="selecteDictItem($event, 'runningForms')" @focus=" getDict( 'dictRunningForms', '988089a029364cd2a3582c2c638145d0' )">
                  <el-option v-for="item in dictRunningForms" :key="item.id" :label="item.fullName" :value="item.enCode"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </template>
          <el-form-item>
            <el-button icon="el-icon-plus" circle @click="addRecordEducations"></el-button>
          </el-form-item>

          <template v-if="isShowCI">
            <el-form-item class="form-item-title" label="职称晋升情况"></el-form-item>
            <template v-for="(rptime, index) in recordPromotes">
              <template v-if="index == 0 || recordPromotes.length == 0">
                <el-form-item label="职称获得时间" :key="'RPGD-' + index">
                  <el-date-picker v-model="rptime.gradeDate" type="date" placeholder="选择日期"></el-date-picker>
                  <el-button type="text" class="btn-delCertResume" @click="delRecordPromotes(rptime, index)">删除</el-button>
                </el-form-item>
                <el-form-item label="等级" :key="'RPPT-' + index">
                  <el-select v-model="rptime.professionTypeGrade" placeholder="请选择等级" filterable allow-create default-first-option @focus="getDictCareerLevel(typeGradeID)" @change="selecteCareerLevel">
                    <el-option v-for="item in dictCareerLevel" :key="item.id" :label="item.fullName" :value="item.enCode"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所在单位" :key="'RPAU-' + index">
                  <el-input v-model="rptime.affiliatedUnit" placeholder="请输入所在单位"></el-input>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item :key="'RP--' + index">
                  <el-divider></el-divider>
                </el-form-item>
                <el-form-item label="职称获得时间" :key="'RPGD-' + index">
                  <el-date-picker v-model="rptime.gradeDate" type="date" placeholder="选择日期"></el-date-picker>
                  <el-button type="text" class="btn-delCertResume" @click="delRecordPromotes(rptime, index)">删除</el-button>
                </el-form-item>
                <el-form-item :label="`${cpitem.careerName}等级`" :key="'RPPT-' + index">
                  <el-select v-model="rptime.professionTypeGrade" placeholder="请选择等级" filterable allow-create default-first-option
                             @focus="getDictCareerLevel(typeGradeID)" @change="selecteCareerLevel">
                    <el-option v-for="item in dictCareerLevel" :key="item.id" :label="item.fullName" :value="item.enCode"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所在单位" :key="'RPAU-' + index">
                  <el-input v-model="rptime.affiliatedUnit" placeholder="请输入所在单位"></el-input>
                </el-form-item>
              </template>
            </template>

            <el-form-item>
              <el-button icon="el-icon-plus" circle @click="addRecordPromotes"></el-button>
            </el-form-item>
          </template>

          <el-form-item class="form-item-title" label="岗位"> </el-form-item>
          <template v-for="(rposttime, index) in recordPositions">
            <template v-if="index == 0 || recordPositions.length == 0">
              <el-form-item label="岗位开始时间" :key="'RPSD-' + index">
                <el-date-picker v-model="rposttime.startDate" type="date" placeholder="选择日期"></el-date-picker>
                <el-button type="text" class="btn-delCertResume" @click="delRecordPositions(rposttime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="岗位结束时间" :key="'RPED-' + index">
                <el-date-picker v-model="rposttime.endDate" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="培训单位" :key="'RPTU-' + index">
                <el-input v-model="rposttime.trainUnit" placeholder="请输入培训单位"></el-input>
              </el-form-item>
              <el-form-item label="主办单位" :key="'RPSU-' + index">
                <el-input v-model="rposttime.sponsorUnit" placeholder="请输入主办单位"></el-input>
              </el-form-item>
              <el-form-item label="地点" :key="'RPD-' + index">
                <el-input v-model="rposttime.address" placeholder="请输入地点"></el-input>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item :key="'RPpt--' + index">
                <el-divider></el-divider>
              </el-form-item>
              <el-form-item label="岗位开始时间" :key="'RPSD-' + index">
                <el-date-picker v-model="rposttime.startDate" type="date" placeholder="选择日期"></el-date-picker>
                <el-button type="text" class="btn-delCertResume" @click="delRecordPositions(rposttime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="岗位结束时间" :key="'RPED-' + index">
                <el-date-picker v-model="rposttime.endDate" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="培训单位" :key="'RPTU-' + index">
                <el-input v-model="rposttime.trainUnit" placeholder="请输入培训单位"></el-input>
              </el-form-item>
              <el-form-item label="主办单位" :key="'RPSU-' + index">
                <el-input v-model="rposttime.sponsorUnit" placeholder="请输入主办单位"></el-input>
              </el-form-item>
              <el-form-item label="地点" :key="'RPD-' + index">
                <el-input v-model="rposttime.address" placeholder="请输入地点"></el-input>
              </el-form-item>
            </template>
          </template>

          <el-form-item>
            <el-button icon="el-icon-plus" circle @click="addRecordPositions"></el-button>
          </el-form-item>

          <el-form-item class="form-item-title" label="发表论文"></el-form-item>
          <template v-for="(rpaperstime, index) in recordPapers">
            <template v-if="index == 0 || recordPapers.length == 0">
              <el-form-item label="发表时间" :key="'RPAPP-' + index">
                <el-date-picker v-model="rpaperstime.postedTime" type="date" placeholder="选择日期"></el-date-picker>
                <el-button
                  type="text" class="btn-delCertResume" @click="delRecordPapers(rpaperstime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="论文题目" :key="'RPAPPT-' + index">
                <el-input v-model="rpaperstime.paperTitle" placeholder="请输入论文题目"></el-input>
              </el-form-item>
              <el-form-item label="链接地址" :key="'RPAPLD-' + index">
                <el-input v-model="rpaperstime.linkAddress" placeholder="请输入链接地址"></el-input>
              </el-form-item>
              <el-form-item label="刊物名称" :key="'RPAPPN-' + index">
                <el-input v-model="rpaperstime.periodicalName" placeholder="请输入刊物名称"></el-input>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item :key="'RPpst--' + index">
                <el-divider></el-divider>
              </el-form-item>
              <el-form-item label="发表时间" :key="'RPAPP-' + index">
                <el-date-picker v-model="rpaperstime.postedTime" type="date" placeholder="选择日期"></el-date-picker>
                <el-button type="text" class="btn-delCertResume" @click="delRecordPapers(rpaperstime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="论文题目" :key="'RPAPPT-' + index">
                <el-input v-model="rpaperstime.paperTitle" placeholder="请输入论文题目"></el-input>
              </el-form-item>
              <el-form-item label="链接地址" :key="'RPAPLD-' + index">
                <el-input v-model="rpaperstime.linkAddress" placeholder="请输入链接地址"></el-input>
              </el-form-item>
              <el-form-item label="刊物名称" :key="'RPAPPN-' + index">
                <el-input v-model="rpaperstime.periodicalName" placeholder="请输入刊物名称"></el-input>
              </el-form-item>
            </template>
          </template>

          <el-form-item>
            <el-button icon="el-icon-plus" circle @click="addRecordPapers"></el-button>
          </el-form-item>

          <el-form-item class="form-item-title" label="工作经历"></el-form-item>
          <template v-for="(rwetime, index) in recordWorkExperiences">
            <template v-if="index == 0 || recordWorkExperiences.length == 0">
              <el-form-item label="起止年月" :key="'RWEtime-' + index">
                <el-date-picker v-model="rwetime.newCRtime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                <el-button type="text" class="btn-delCertResume" @click="delRecordWorkExperiences(rwetime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="单位" :key="'RWEUN-' + index">
                <el-input v-model="rwetime.unitName" placeholder="请输入单位"></el-input>
              </el-form-item>
              <el-form-item label="职务" :key="'RWEP-' + index">
                <el-input v-model="rwetime.position" placeholder="请输入职务"></el-input>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item :key="'RWErwe--' + index">
                <el-divider></el-divider>
              </el-form-item>

              <el-form-item label="起止年月" :key="'RWEtime-' + index">
                <el-date-picker v-model="rwetime.newCRtime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                <el-button type="text" class="btn-delCertResume" @click="delRecordWorkExperiences(rwetime, index)">删除</el-button>
              </el-form-item>
              <el-form-item label="单位" :key="'RWEUN-' + index">
                <el-input v-model="rwetime.unitName" placeholder="请输入单位"></el-input>
              </el-form-item>
              <el-form-item label="职务" :key="'RWEP-' + index">
                <el-input v-model="rwetime.position" placeholder="请输入职务"></el-input>
              </el-form-item>
            </template>
          </template>

          <el-form-item>
            <el-button icon="el-icon-plus" circle @click="addRecordWorkExperiences"></el-button>
          </el-form-item>

          <el-form-item>
            <el-divider></el-divider>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--  -->
    <el-dialog title="提示信息" :visible.sync="isDialogVisible" width="30%">
      <span>{{ dialogMsg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="ruleForm.code != ''" @click="golink">去绑定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBaseItemList,
  delRecordEducation,
  delRecordPosition,
  delRecordWorkExperience,
  delRecordPromote,
  delRecordPaper,
  getBindUnited,
  getCertUnitList,
  getProfessionName,
  createProfessionalApply,
} from "@/api/professional";
import { getUserInfo } from "@/api/user";
import { dict } from "@/api/dict";
import templateFooter from "../template/first/templateFooter.vue";

export default {
  components: { templateFooter },
  name: "user-professional-information",
  props: {
    cpitem: Object,
  },
  data() {
    return {
      baseUserInfo: {},
      unitedList: [],
      // 教练员:1, 裁判员:2, 领队:3, 队医:4, 工作人员:5
      ptype: 1,
      ruleForm: {
        tenantId: "",
        belowTenantId: "",
        belowTenantName: "",
        itemName: "",
        items: "",
        careerName: "",
        careerId: "",
        grade: "",
        careerCertificate: "",
        certificateNumber: "",
        getTime: "",
        certificatePhoto: "",

        name: "",
        natives: "",
        sex: 1,
        birthDate: "",
        identityCard: "",
        identityAddress: "",
        nation: "",
        phone: "",

        recordInformations: {
          name: "",
          natives: "",
          sex: 1,
          birthDate: "",
          identityCard: "",
          identityAddress: "",
          nation: "",
          phone: "",
          affiliatedUnit: "",
          areUnitId: "",
          areUnitName: "",
          authorizedType: "",
          createBy: "",
          createTime: "",
          delFlag: "",
          englishLevel: "",
          familyAddress: "",
          gradeDate: "",
          id: "",
          named: "",
          namedDate: "",
          placeUnitType: "",
          politics: "",
          positionType: "",
          professionType: "",
          professionTypeGrade: "",
          professionalGrade: "",
          qid: "",
        },
        recordEducations: [],
        recordPapers: [],
        recordPositions: [],
        recordPromotes: [],
        recordWorkExperiences: [],

        teachingDate: "",
        teachingItem: "",
        teachingItemId: "",

        topAthleteGrade: "",
        topEducation: "",
        uid: "",
        unitId: "",
        unitName: "",
        updateBy: "",
        updateTime: "",

        member: false,
        code: "",
        personnelData: {
          code: "",
          createBy: "",
          createTime: "",
          delFlag: 0,
          id: "",
          pid: "",
          qid: "",
          tenantId: "",
          updateBy: "",
          updateTime: "",
        },
      },
      rules: {
        belowTenantName: [
          { required: true, message: "请选择认证单位", trigger: "change" },
        ],
        careerId: [{ required: true, message: "职位名称", trigger: "blur" }],

        itemName: [
          { required: true, message: "请选择体育项目", trigger: "change" },
        ],
        grade: [{ required: true, message: "请输入级别", trigger: "blur" }],
        careerCertificate: [
          { required: true, message: "请输入证书名称", trigger: "blur" },
        ],
        certificateNumber: [
          { required: true, message: "请输入证书编号", trigger: "blur" },
        ],
        getTime: [
          {
            type: "string",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        certificatePhoto: [
          { required: true, message: "上传证书附件", trigger: "blur" },
        ],

        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        natives: [{ required: true, message: "请输入籍贯", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        birthDate: [
          { required: true, message: "请输入出生日期", trigger: "blur" },
        ],
        identityCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
        ],
        identityAddress: [
          { required: true, message: "请输入身份证住址", trigger: "blur" },
        ],
        nation: [{ required: true, message: "请输入民族", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
      },
      // 是否显示 证书信息
      isShowCI: false,

      // 项目列表
      options: [],
      // 认证履历学历信息(RecordEducation)表实体类
      recordEducations: [
        {
          createBy: "",
          createTime: "",
          delFlag: 0,
          education: "",
          endDate: "",
          id: "",
          rid: "",
          runningForms: "",
          schoolGraduation: "",
          schooling: "",
          specialty: "",
          startDate: "",
          tenantId: "",
          updateBy: "",
          updateTime: "",
          newCRtime: [],
        },
      ],
      // 认证履历论文信息(RecordPaper)表实体类
      recordPapers: [
        {
          createBy: "",
          createTime: "",
          delFlag: 0,
          id: "",
          linkAddress: "",
          paperTitle: "",
          periodicalName: "",
          postedTime: "",
          rid: "",
          tenantId: "",
          updateBy: "",
          updateTime: "",
        },
      ],
      // 认证履历岗位信息(RecordPosition)表实体类
      recordPositions: [
        {
          address: "",
          createBy: "",
          createTime: "",
          delFlag: 0,
          endDate: "",
          id: "",
          rid: "",
          sponsorUnit: "",
          startDate: "",
          tenantId: "",
          trainUnit: "",
          updateBy: "",
          updateTime: "",
        },
      ],
      // 认证履历职称晋升(RecordPromote)表实体类
      recordPromotes: [
        {
          affiliatedUnit: "",
          createBy: "",
          createTime: "",
          delFlag: 0,
          gradeDate: "",
          id: "",
          professionType: "",
          professionTypeGrade: "",
          rid: "",
          tenantId: "",
          updateBy: "",
          updateTime: "",
        },
      ],
      // 认证履历工作经历(RecordWorkExperience)表实体类
      recordWorkExperiences: [
        {
          createBy: "",
          createTime: "",
          delFlag: 0,
          endDate: "",
          id: "",
          position: "",
          rid: "",
          startDate: "",
          tenantId: "",
          unitName: "",
          updateBy: "",
          updateTime: "",
          newCRtime: [],
        },
      ],

      // 职业证书图片
      fileList: [],
      // 职业名称 f6ba8b07fbae4a2daa22177df2588c4e
      dictCareerCategory: [],
      // 职业级别  ( ps：先选职业名称 )
      dictCareerLevel: [],
      // 其他人员类别 df2f5be9b3824839b5f0ff751c8329a2
      dictOtherPersonnelCategory: [],
      // 岗位等级 814d2e311b7944d2ae10bbee4b9a3f14
      dictPostGrades: [],
      // 英语水平 93c2ee136a584576b732c67c404fd3dd
      dictEnglishLevel: [],
      // 编制类型 bdec0a02b98a4ae19f5c710db73896d6
      dictPrepareType: [],
      // 政治面貌 c144f1c8a9ea4496be68098ee34e7573
      dictPoliticalLandscape: [],
      // 办学形式 988089a029364cd2a3582c2c638145d0
      dictRunningForms: [],
      // 岗位类别 b72bbb1f863846dfbbcbf8f1140b5b99
      dictJobCategory: [],
      // 职称 78790f1d66d64a819fd9f9b88d65c8ef
      dictJobTitle: [],
      // 所在单位类型 bec1498d87d641dea5cbe3ff363f413e
      dictWorkUnitType: [],
      // 教练级别 9230bc5d524541869bcffa654d552e11
      dictCoachGrade: [],
      // 裁判级别 059aece5517f4160834763cf4d971cdb
      dictRefereeGrade: [],
      // 运动员级别 3545f3a968ea4d42ab1eb5d3408fcba1
      dictAthleteGrade: [],
      // 学历 6a6d6fb541b742fbae7e8888528baa16
      dictEducation: [],
      // 民族 b6cd65a763fa45eb9fe98e5057693e40
      dictNation: [],

      // 表单字段赋值字典字段集
      formDicts: [
        // "politics",
        // "named",
        // "authorizedType",
        // "placeUnitType",
        // "positionType",
        // "professionTypeGrade",
        // "professionalGrade",
        // "topAthleteGrade",
        // "topEducation",
        // "englishLevel",
        // "runningForms",
      ],
      // 查询字典字段集
      dictAll: [
        // {
        //   fname: "politics",
        //   dname: "dictPoliticalLandscape",
        //   id: "c144f1c8a9ea4496be68098ee34e7573",
        // },
        // {
        //   fname: "named",
        //   dname: "dictJobTitle",
        //   id: "78790f1d66d64a819fd9f9b88d65c8ef",
        // },
        // {
        //   fname: "authorizedType",
        //   dname: "dictPrepareType",
        //   id: "bdec0a02b98a4ae19f5c710db73896d6",
        // },
        // {
        //   fname: "placeUnitType",
        //   dname: "dictWorkUnitType",
        //   id: "bec1498d87d641dea5cbe3ff363f413e",
        // },
        // {
        //   fname: "positionType",
        //   dname: "dictJobCategory",
        //   id: "b72bbb1f863846dfbbcbf8f1140b5b99",
        // },
        // {
        //   fname: "professionalGrade",
        //   dname: "dictPostGrades",
        //   id: "814d2e311b7944d2ae10bbee4b9a3f14",
        // },
        // {
        //   fname: "topAthleteGrade",
        //   dname: "dictAthleteGrade",
        //   id: "3545f3a968ea4d42ab1eb5d3408fcba1",
        // },
        // {
        //   fname: "topEducation",
        //   dname: "dictEducation",
        //   id: "6a6d6fb541b742fbae7e8888528baa16",
        // },
        // {
        //   fname: "englishLevel",
        //   dname: "dictEnglishLevel",
        //   id: "93c2ee136a584576b732c67c404fd3dd",
        // },
        {
          fname: "runningForms",
          dname: "dictRunningForms",
          id: "988089a029364cd2a3582c2c638145d0",
        },
      ],
      // 人员的等级
      staffLevel: {},
      // 表单可添加记录字段
      aFormItems: ["recordEducations", "recordPromotes"],
      // 表单可添加字段赋值字典字段集
      aFormDicts: ["runningForms", "professionTypeGrade"],
      // 可添加查询字典字段集
      aDictAll: [
        {
          fname: "runningForms",
          dname: "dictRunningForms",
          id: "988089a029364cd2a3582c2c638145d0",
        },
      ],
      // 认证模板
      tenant_id: "sundo",
      // 认证单位
      certUnitList: [],
      // 动态模板
      customForms: [],
      applyForm: {},
      applyRules: {},
      // 职业等级 id
      typeGradeID: "",
      // 检查是否为会员
      isDialogVisible: false,
      dialogMsg: "",
      applyId: "",
      // 没有模板
      noCode: "",
      // 动态图片
      cfileList: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    const self = this;
    this.tenant_id = window.localStorage.getItem("tenant_id") || "sundo";
    this.getCertUnitLists();

    this.getDictCareerName(
      "dictCareerCategory",
      "f6ba8b07fbae4a2daa22177df2588c4e"
    );

    // 新建
    if (this.cpitem.id == "") {
      // 没有id
      this.getUserInfos();
      this.ruleForm.qid = this.cpitem.id;
      this.ruleForm.teachingItem = this.cpitem.itemName;
      this.ruleForm.professionTypeGrade = this.cpitem.grade;
    } else {
      self.getDetails();
      self.showDicts();

      setTimeout(() => {
        self.dictFilter(1);
      }, 1600);
    }
  },
  methods: {
    // 获取当前用户的基本信息
    getUserInfos() {
      const self = this;
      self.$store.commit("showLoading");
      getUserInfo().then((res) => {
        if (res.code == 200) {
          self.baseUserInfo = res.data;
          let _u = res.data;
          self.ruleForm.name = _u.realName;
          self.ruleForm.birthDate = _u.birthday.slice(0, 10);
          self.ruleForm.phone = _u.mobilePhone;
          self.ruleForm.sex = _u.gender;
          self.ruleForm.identityCard = _u.certificatesNumber;
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },

    // 获取认证列表
    getLists() {
      const self = this;
      self.$store.commit("showLoading");
      getList().then((res) => {
        if (res.code == 200) {
          self.certificateList = res.data;
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    // 体育项目
    getBaseItemLists() {
      const self = this;
      getBaseItemList({ itemType: 2 }).then((res) => {
        if (res.code == 200) {
          self.options = res.data;
          if (self.cpitem.careerName && self.cpitem.careerName != "") {
            res.data.map((item) => {
              if (item.id == self.cpitem.careerName) {
                self.ruleForm.careerName = item.careerName;
              }
            });
          }
        } else {
          self.$message(res.msg);
        }
      });
    },
    funItems(value) {
      this.options.map((item) => {
        if (item.id == value) {
          this.ruleForm.itemName = item.itemName;
          this.ruleForm.items = item.id;
          this.getProfessionNames();
        }
      });
    },
    // 字典查询
    getDict(fName, id) {
      dict(id, null).then((res) => {
        if (res.code == 200 && res.data) {
          this[fName] = res.data.list;
        }
      });
    },
    // 字典选择
    selecteDictItem(value, fName) {
      this.ruleForm[fName] = value;
    },
    // 回显字典函数
    showDicts() {
      const self = this;
      self.dictAll.map((dItem) => {
        self.getDict(dItem.dname, dItem.id);
      });
    },
    // 过滤字典 stype: 1:回显，2：修改
    dictFilter(stype) {
      const self = this;
      this.dictFilterRunningForms(stype);
      self.dictAll.map((dItem) => {
        self.formDicts.map((fItem) => {
          if (dItem.fname == fItem) {
            let val = self.ruleForm[fItem];
            let eVal = stype == 1 ? "enCode" : "fullName";
            let fVal = stype == 2 ? "enCode" : "fullName";
            let rval = self[dItem.dname].filter(
              (subfItem) => subfItem[eVal] == val
            );
            self.ruleForm[fItem] = rval.length > 0 ? rval[0][fVal] : val;
          }
          return;
        });
      });
    },

    // 时间区间处理 -- 拆分
    timeInterval(list) {
      list.map(async (item) => {
        item.startDate = item.newCRtime[0] || "";
        item.endDate = item.newCRtime[1] || "";
      });
      return list;
    },
    // 时间区间处理 -- 还原
    reTimeInterval(list) {
      list.map(async (item) => {
        item.newCRtime = [item.startDate, item.endDate] || [];
      });
      return list;
    },
    // 字典查询 -- - 获取职业名称
    getDictCareerName(fName, id) {
      dict(id, null).then((res) => {
        if (res.code == 200 && res.data) {
          this[fName] = res.data.list;
          if (this.cpitem.careerName && this.cpitem.careerName != "") {
            res.data.list.map((item) => {
              if (item.fullName == this.cpitem.careerName) {
                this.ruleForm.careerId = this.cpitem.careerName;
                this.typeGradeID = item.enCode;
                this.getDictCareerLevel(item.enCode);
              }
            });
          }
        }
      });
    },
    // 字典选择 --  -职业名称
    selecteDictItemCareerName(value, fName) {
      this.dictCareerCategory.map((item) => {
        if (item.enCode == value) {
          this.ruleForm[fName] = value;
          this.ruleForm.careerName = item.fullName;
          this.getProfessionNames();
          this.getDictCareerLevel(value);
          this.typeGradeID = value;
          this.isShowCI = item.fullName == "工作人员" ? false : true;
          this.rulesCertificate(item.fullName);
        }
      });
    },

    // 字典查询 --- 职业等级（级别）
    getDictCareerLevel(id) {
      dict(id, null).then((res) => {
        if (res.code == 200 && res.data) {
          this.dictCareerLevel = res.data.list;
          if (this.cpitem.careerName && this.cpitem.careerName != "") {
            res.data.list.map((item) => {
              if (item.fullName == this.cpitem.careerName) {
                this.ruleForm.careerId = this.cpitem.careerName;
                this.typeGradeID = item.enCode;
                this.getDictCareerLevel(item.enCode);
              }
              // 回显晋升等级
              this.cpitem.recordPromotes.map((record) => {
                if (item.enCode == record.professionTypeGrade) {
                  record.professionTypeGrade = item.fullName;
                }
              });
            });
          }
        }
      });
    },
    // 选择职业
    selecteCareerLevel(value) {
      // this.ruleForm.grade = "";
      this.dictCareerLevel.map((item) => {
        if (item.fullName == value) {
          this.ruleForm.grade = item.fullName;
        }
      });
    },

    /**
     * stype: 1:回显，2：修改
     * aFormItems
      学历（学位）教育情况-- 办学形式
      职称晋升情况 -- 等级
   */
    dictFilterRunningForms(stype) {
      const self = this;
      self.aFormItems.map((afitem) => {
        self.ruleForm[afitem].map((item) => {
          self.aDictAll.map((dItem) => {
            let val = item[dItem.fname];
            let eVal = stype == 1 ? "enCode" : "fullName";
            let fVal = stype == 2 ? "enCode" : "fullName";
            let rval = self[dItem.dname].filter(
              (subItem) => subItem[eVal] == val
            );
            item[dItem.fname] = rval.length > 0 ? rval[0][fVal] : val;
          });
        });
      });
    },

    //  人员信息表(教练员，裁判员，运动员等)新增或修改数据
    submitForm(formName) {
      const self = this;
      if (this.noCode == "") {
        this.dialogMsg = "请先配置当前人员项目模板";
        this.isDialogVisible = true;
        return false;
      }

      self.$refs[formName].validate((valid) => {
        if (valid) {
          let reItem = this.timeInterval(this.recordEducations);
          let rweItem = this.timeInterval(this.recordWorkExperiences);
          this.ruleForm.recordEducations =
            reItem[0]?.endDate == "" || reItem[0]?.schooling == ""
              ? []
              : reItem;
          this.ruleForm.recordWorkExperiences =
            rweItem[0]?.endDate == "" || rweItem[0]?.position == ""
              ? []
              : rweItem;

          this.ruleForm.recordPapers =
            this.recordPapers[0]?.postedTime == "" ||
            this.recordPapers[0]?.periodicalName == ""
              ? []
              : this.recordPapers;
          this.ruleForm.recordPositions =
            this.recordPositions[0]?.endDate == "" ||
            this.recordPositions[0]?.sponsorUnit == ""
              ? []
              : this.recordPositions;
          this.ruleForm.recordPromotes =
            this.recordPromotes[0]?.gradeDate == "" ||
            this.recordPromotes[0]?.professionTypeGrade == ""
              ? []
              : this.recordPromotes;

          this.dictFilter(2);
          // 基本信息
          this.ruleForm.recordInformations.name = this.ruleForm.name;
          this.ruleForm.recordInformations.natives = this.ruleForm.natives;
          this.ruleForm.recordInformations.sex = this.ruleForm.sex;
          this.ruleForm.recordInformations.birthDate = this.ruleForm.birthDate;
          this.ruleForm.recordInformations.identityCard =
            this.ruleForm.identityCard;
          this.ruleForm.recordInformations.identityAddress =
            this.ruleForm.identityAddress;
          this.ruleForm.recordInformations.nation = this.ruleForm.nation;
          this.ruleForm.recordInformations.phone = this.ruleForm.phone;

          let _pname = this.cpitem.careerName;
          let ptype =
            _pname == "教练员"
              ? 1
              : _pname == "裁判员"
              ? 2
              : _pname == "领队"
              ? 3
              : _pname == "队医"
              ? 4
              : _pname == "工作人员"
              ? 5
              : 6;
          this.ruleForm.professionTypeGrade = ptype;
          this.customForms.map((item) => {
            item.defaultValue = this.ruleForm[item.vModel];
            if (item.tagIcon === "upload") {
              item.defaultValue = this.cfileList;
              this.ruleForm[item.vModel] = this.cfileList;
            }
          });
          this.ruleForm.code = JSON.stringify(this.customForms);
          this.ruleForm.personnelData.code = JSON.stringify(this.customForms);

          if (self.cpitem.id != "") {
            if (
              self.cpitem.recordPromotes.length > 0 &&
              self.cpitem.recordPromotes[0].professionTypeGrade != ""
            ) {
              this.dictCareerLevel.map((rpitem) => {
                this.ruleForm.recordPromotes.map((item) => {
                  if (rpitem.fullName == item.professionTypeGrade) {
                    item.professionTypeGrade = rpitem.enCode;
                  }
                });
              });
            }
          }
          let params = this.ruleForm;
          //  return false;

          createProfessionalApply(params).then((res) => {
            if (res.code == 200) {
              self.showPIList();
            } else if (res.data && params.member) {
              this.dialogMsg = res.msg;
              this.applyId = res.data;
              this.isDialogVisible = true;
            }
          });
        } else {
          this.$message("请先完善信息！");
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 获取已绑定单位:获取代表单位
    getBindUniteds() {
      const self = this;
      getBindUnited(null, self.cpitem.id).then((res) => {
        if (res.code == 200) {
          self.unitedList = res.data;
        } else {
          self.$message(res.msg);
        }
      });
    },

    // 添加学历（学位）教育情况------------------------
    addRecordEducations() {
      //  let items = Object.assign({}, self.personalForm);
      let item = {
        schoolGraduation: "",
        specialty: "",
        schooling: "",
        education: "",
        runningForms: "",
        newCRtime: [],
        tenantId: "",
        id: "",
        rid: "",
        delFlag: 0,
      };
      this.recordEducations.push(item);
    },
    // 删除学历（学位）教育情况-- (远程)
    recordDelRecordEducations(id) {
      delRecordEducation(null, id).then((res) => {
        if (res.code == 200 && res.data) {
          this.$message(res.msg);
        } else {
          this.$message(res.msg);
          return false;
        }
      });
    },
    // 删除学历（学位）教育情况-- (本地)
    delRecordEducations(citem, i) {
      if (citem.id) {
        this.recordDelRecordEducations(citem.id);
      }
      this.recordEducations.map((item, index) => {
        if (
          (i == index && i != 0) ||
          (i == 0 && index == 0 && this.recordEducations.length != 1)
        ) {
          this.recordEducations.splice(index, 1);
        } else if (i == 0 && this.recordEducations.length == 1) {
          this.recordEducations = [
            {
              schoolGraduation: "",
              specialty: "",
              schooling: "",
              education: "",
              runningForms: "",
              newCRtime: [],
              tenantId: "",
              id: "",
              rid: "",
              delFlag: 0,
            },
          ];
        }
      });
    },

    // 添加职称晋升情况-------------------------------
    addRecordPromotes() {
      let item = {
        affiliatedUnit: "",
        gradeDate: "",
        professionTypeGrade: "",
        tenantId: "",
        id: "",
        rid: "",
        delFlag: 0,
      };
      this.recordPromotes.push(item);
    },
    // 删除职称晋升情况-- (远程)
    recordRecordPromotes(id) {
      delRecordPromote(null, id).then((res) => {
        if (res.code == 200 && res.data) {
          this.$message(res.msg);
        } else {
          this.$message(res.msg);
          return false;
        }
      });
    },
    // 删除职称晋升情况-- (本地)
    delRecordPromotes(citem, i) {
      if (citem.id) {
        this.recordRecordPromotes(citem.id);
      }
      this.recordPromotes.map((item, index) => {
        if (
          (i == index && i != 0) ||
          (i == 0 && index == 0 && this.recordPromotes.length != 1)
        ) {
          this.recordPromotes.splice(index, 1);
        } else if (i == 0 && this.recordPromotes.length == 1) {
          this.recordPromotes = [
            {
              affiliatedUnit: "",
              gradeDate: "",
              professionTypeGrade: "",
              tenantId: "",
              id: "",
              rid: "",
              delFlag: 0,
            },
          ];
        }
      });
    },

    // 添加岗位 -------------------------------------
    addRecordPositions() {
      let item = {
        startDate: "",
        endDate: "",
        trainUnit: "",
        sponsorUnit: "",
        address: "",
        tenantId: "",
        id: "",
        rid: "",
        delFlag: 0,
      };
      this.recordPositions.push(item);
    },
    // 删除岗位-- (远程)
    recordRecordPositions(id) {
      delRecordPosition(null, id).then((res) => {
        if (res.code == 200 && res.data) {
          this.$message(res.msg);
        } else {
          this.$message(res.msg);
          return false;
        }
      });
    },
    // 删除岗位-- (本地)
    delRecordPositions(citem, i) {
      if (citem.id) {
        this.recordRecordPositions(citem.id);
      }
      this.recordPositions.map((item, index) => {
        if (
          (i == index && i != 0) ||
          (i == 0 && index == 0 && this.recordPositions.length != 1)
        ) {
          this.recordPositions.splice(index, 1);
        } else if (i == 0 && this.recordPositions.length == 1) {
          this.recordPositions = [
            {
              startDate: "",
              endDate: "",
              trainUnit: "",
              sponsorUnit: "",
              address: "",
              tenantId: "",
              id: "",
              rid: "",
              delFlag: 0,
            },
          ];
        }
      });
    },

    // 添加发表论文 ----------------------------------
    addRecordPapers() {
      let item = {
        linkAddress: "",
        paperTitle: "",
        periodicalName: "",
        postedTime: "",
        tenantId: "",
        id: "",
        rid: "",
        delFlag: 0,
      };
      this.recordPapers.push(item);
    },
    // 删除发表论文-- (远程)
    recordRecordPapers(id) {
      delRecordPaper(null, id).then((res) => {
        if (res.code == 200 && res.data) {
          this.$message(res.msg);
        } else {
          this.$message(res.msg);
          return false;
        }
      });
    },
    // 删除发表论文-- (本地)
    delRecordPapers(citem, i) {
      if (citem.id) {
        this.recordRecordPapers(citem.id);
      }
      this.recordPapers.map((item, index) => {
        if (
          (i == index && i != 0) ||
          (i == 0 && index == 0 && this.recordPapers.length != 1)
        ) {
          this.recordPapers.splice(index, 1);
        } else if (i == 0 && this.recordPapers.length == 1) {
          this.recordPapers = [
            {
              linkAddress: "",
              paperTitle: "",
              periodicalName: "",
              postedTime: "",
              tenantId: "",
              id: "",
              rid: "",
              delFlag: 0,
            },
          ];
        }
      });
    },

    // 添加工作经历
    addRecordWorkExperiences() {
      let item = {
        startDate: "",
        endDate: "",
        position: "",
        unitName: "",
        newCRtime: [],
        tenantId: "",
        delFlag: 0,
        id: "",
        rid: "",
      };
      this.recordWorkExperiences.push(item);
    },
    // 删除工作经历-- (远程)------------------------------
    recordRecordWorkExperiences(id) {
      delRecordWorkExperience(null, id).then((res) => {
        if (res.code == 200 && res.data) {
          this.$message(res.msg);
        } else {
          this.$message(res.msg);
          return false;
        }
      });
    },
    // 删除工作经历-- (本地)
    delRecordWorkExperiences(citem, i) {
      if (citem.id) {
        this.recordRecordWorkExperiences(citem.id);
      }
      this.recordWorkExperiences.map((item, index) => {
        if (
          (i == index && i != 0) ||
          (i == 0 && index == 0 && this.recordWorkExperiences.length != 1)
        ) {
          this.recordWorkExperiences.splice(index, 1);
        } else if (i == 0 && this.recordWorkExperiences.length == 1) {
          this.recordWorkExperiences = [
            {
              startDate: "",
              endDate: "",
              position: "",
              unitName: "",
              newCRtime: [],
              tenantId: "",
              delFlag: 0,
              id: "",
              rid: "",
            },
          ];
        }
      });
    },
    // 显示认证列表
    showPIList() {
      this.$emit("func", true);
    },
    // 获取可认证单位列表
    getCertUnitLists() {
      const self = this;
      let id = self.tenant_id;
      getCertUnitList(null, id).then((res) => {
        self.certUnitList = res;
      });
    },
    // 选择可认证单位
    selecteCertUnit(id) {
      this.certUnitList.map((item) => {
        if (item.fullName == id) {
          this.ruleForm.belowTenantId = item.id;
          this.ruleForm.belowTenantName = item.fullName;
          this.getProfessionNames();
        }
      });
    },
    // 图片处理 -- 移除
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 图片处理 -- 显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgdialogVisible = true;
    },
    // 图片处理 -- 添加
    handleAvatarSuccess(res, file) {
      let name = res.data.url;
      this.ruleForm.certificatePhoto = "";
      let imgUrl = {
        name,
        url: this.loadUrl(res.data.url),
      };
      this.fileList.push(imgUrl);
      this.fileList.map((item) => {
        this.ruleForm.certificatePhoto += item.name + ",";
      });
      this.ruleForm.certificatePhoto = this.ruleForm.certificatePhoto.substring(
        0,
        this.ruleForm.certificatePhoto.lastIndexOf(",")
      );
    },
    // 获取人员申请动态模板
    getProfessionNames() {
      const self = this;
      let tenantId = this.ruleForm.belowTenantId;
      let itemId = this.ruleForm.items;
      let professionType = this.ruleForm.careerName;
      let params = {
        itemId,
        professionType,
        tenantId,
      };

      if (professionType == "工作人员") {
        params = {
          professionType,
          tenantId,
        };
      } else {
        params = {
          itemId,
          professionType,
          tenantId,
        };
      }

      if (
        (tenantId != "" && professionType == "工作人员") ||
        (tenantId != "" && itemId != "" && professionType != "工作人员")
      ) {
        getProfessionName(params).then((res) => {
          this.$nextTick(() => {});
          if (res.code == 200) {
            let _code = res.data.code;
            this.noCode = _code;
            this.ruleForm.member =
              res.data.member == null
                ? false
                : res.data.member == false
                ? false
                : true;

            this.ruleForm.personnelData.pid = res.data.id;
            this.customForms = JSON.parse(_code);
            this.customForms.map((item) => {
              this.$set(this.ruleForm, item.vModel, item.defaultValue || "");
              // if (item.tagIcon === "upload") {
              //   this.cfileList = item.defaultValue;
              // }
              // this.$set(this.rules, item.vModel, {
              //   required: item.required,
              //   message: item.placeholder,
              //   trigger: item.tagIcon == "select" ? "change" : "blur",
              // });
            });
          } else {
            // this.$message(res.msg);
            this.noCode = "";
            this.customForms = [];
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      }
    },
    // 动态图片处理 -- 移除
    cRemove(file, fileList) {
      this.cfileList = fileList;
    },
    // 动态图片处理 -- 显示
    cPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgdialogVisible = true;
    },
    // 动态图片处理 -- 添加
    cSuccess(res, file) {
      let name = res.data.url;
      let cPhoto = "";
      let imgUrl = {
        name,
        url: this.loadUrl(res.data.url),
      };
      this.cfileList.push(imgUrl);
      this.cfileList.map((item) => {
        cPhoto += item.name + ",";
      });
      cPhoto = cPhoto.substring(0, cPhoto.lastIndexOf(","));
      this.customForms.map((cfitem) => {
        if (cfitem.tagIcon === "upload") {
          this.ruleForm[cfitem.vModel] = cPhoto;
        }
      });
    },

    //是否为必填项 证书信息
    rulesCertificate(val) {
      if (val == "工作人员") {
        this.rules.itemName[0].required = false;
        this.rules.grade[0].required = false;
        this.rules.careerCertificate[0].required = false;
        this.rules.certificateNumber[0].required = false;
        this.rules.getTime[0].required = false;
        this.rules.certificatePhoto[0].required = false;
      } else {
        this.rules.itemName[0].required = true;
        this.rules.grade[0].required = true;
        this.rules.careerCertificate[0].required = true;
        this.rules.certificateNumber[0].required = true;
        this.rules.getTime[0].required = true;
        this.rules.certificatePhoto[0].required = true;
      }
    },
    // 修改/回显详情
    getDetails() {
      const self = this;
      self.ruleForm = self.cpitem;
      this.ruleForm.member =
        self.cpitem.member == null
          ? false
          : self.cpitem.member == false
          ? false
          : true;
      // this.ruleForm.personnelData.pid = self.cpitem.id;
      // 职业名称
      // self.cpitem.careerId = self.cpitem.careerName;

      if (self.cpitem.careerName != "工作人员") {
        self.isShowCI = true;
        // 证书信息
        this.getBaseItemLists();
      }

      // 图片回显
      let imgUrl = self.cpitem.certificatePhoto.split(",");
      imgUrl.map((item) => {
        let _imgUrl = {
          name: item,
          url: this.loadUrl(item),
        };
        this.fileList.push(_imgUrl);
      });

      // 基本信息
      let baseInfo = this.cpitem.recordInformations;
      this.$set(this.ruleForm, "name", baseInfo.name);
      this.$set(this.ruleForm, "natives", baseInfo.natives);
      this.$set(this.ruleForm, "sex", baseInfo.sex);
      this.$set(this.ruleForm, "birthDate", baseInfo.birthDate);
      this.$set(this.ruleForm, "identityCard", baseInfo.identityCard);
      this.$set(this.ruleForm, "identityAddress", baseInfo.identityAddress);
      this.$set(this.ruleForm, "nation", baseInfo.nation);
      this.$set(this.ruleForm, "phone", baseInfo.phone);

      // code
      let code = this.cpitem.personnelData.code;
      self.noCode = code;
      self.customForms = JSON.parse(code);
      self.customForms.map((item) => {
        self.$set(this.ruleForm, item.vModel, item.defaultValue || "");
        if (item.tagIcon === "upload") {
          this.cfileList = item.defaultValue;
        }
      });

      // 回显履历
      this.recordPapers = self.cpitem.recordPapers;
      this.recordPromotes = self.cpitem.recordPromotes;
      this.recordPositions = self.cpitem.recordPositions;
      let reItem = this.reTimeInterval(self.cpitem.recordEducations);
      let rweItem = this.reTimeInterval(self.cpitem.recordWorkExperiences);
      this.recordEducations = reItem;
      this.recordWorkExperiences = rweItem;
    },
    // 去成为会员
    golink() {
      this.isDialogVisible = false;
      this.$router.push(`partners-apply/${this.applyId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.professional-info {
  height: 100%;
}
.professional-info-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 12px;
  padding-right: 20px;
  margin-left: 20px;
  border-bottom: 1px solid #ececec;
}
.professional-info-wrap {
  width: 100%;
  height: calc(100vh - 218px);
  overflow: hidden;
  overflow-y: scroll;
}
.professional-ruleForm {
  width: 800px;
  padding: 20px;
  .el-form-item {
    padding-right: 16%;
  }
  .btn-delCertResume {
    padding-left: 16px;
    cursor: pointer;
  }
  .tips-text {
    padding: 0 10px;
    color: #999;
    font-size: 13px;
  }
}

.form-item-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 12px;
  border-top: 1px solid #ececec;
  /deep/ .el-form-item__label {
    text-align: left;
    color: #000;
  }
}
</style>
