<template>
  <div class="user-content">
      <div class="bold">
        <h2>职业认证</h2>
      </div>
      <div class="contain">
        <div class="sideBar" >
          <ul>
            <li v-for="(meun, index) in SubMenu" :key="index" :class="{ on: selected == meun.value }" @click="sideSubMenu(meun)">{{ meun.label }}</li>
          </ul>
        </div>
        <!-- 我要认证 -->
        <div class="contain-cont" v-if="selected == 'pc'">
          <template v-if="pinfoShow">
            <div class="btn-certification">
              <!--  @click="bigDialog('newCert', 0) -->
              <el-button type="primary" @click="newCInfo()">我要认证</el-button>
            </div>
            <el-scrollbar class="u-STB">
              <div class="pc-cont">

                <div class="card" v-for="c_item in certificateList" :key="c_item.id" @click=" bigDialog('certStatus', c_item.attestationState, c_item)">
                  <h2>{{ c_item.itemName != "" ? c_item.itemName : c_item.careerName }}</h2>

                  <ul class="card-info">
                    <li>
                      <span class="name">认证单位：</span>
                      <span class="value">{{ c_item.belowTenantName }}</span>
                    </li>
                    <li>
                      <span class="name">职位名称：</span
                      ><span class="value">{{ c_item.careerName }}</span>
                    </li>

                    <li>
                      <span class="name">认证状态：</span>
                      <!-- 认证状态（认证中0、认证成功1、认证失败2） -->
                      <span v-if="c_item.attestationState == 0" class="value warning">认证中</span>
                      <span v-if="c_item.attestationState == 1" class="value success">认证成功</span>
                      <span v-if="c_item.attestationState == 2" class="value danger">认证失败</span>
                    </li>

                    <li v-if="c_item.attestationState == 1">
                      <span class="name">是否公开：</span>
                      <span class="value" @click.stop="changePublic(c_item)">
                        <el-switch v-model="c_item.isopen" active-text="公开"></el-switch>
                      </span>
                    </li>
                  </ul>
                  <!-- <el-button v-if="c_item.attestationState == 1" @click.stop="unitBinding(1, c_item)" class="btn-unit" plain >绑定单位</el-button >
                  <el-button v-if="c_item.attestationState == 1" @click.stop="lookPPCInfo(1, c_item)" class="btn-look" plain >{{ c_item.isRI == "false" ? "申请" : "查看" }}{{ c_item.careerName }}表</el-button > -->
                </div>

              </div>
            </el-scrollbar>
          </template>
          <template v-else>
            <professional-information :cpitem="cpitem" @func="showPI" />
          </template>
        </div>
        <!-- 单位绑定管理 -->
        <div class="contain-cont pc-cont" v-if="selected == 'unit'">
          <el-tabs v-model="activeName" @tab-click="tabhandleClicks">
            <el-tab-pane label="我绑定单位" name="first">
              <el-table v-loading="load" :data="mybingUnitTable" style="width: 100%" class="table_bind">
                <el-table-column fixed prop="unitName" label="单位名称"></el-table-column>
                <!-- <el-table-column prop="careerName" label="联系人" width="120">
                </el-table-column>
                <el-table-column prop="userQname" label="联系电话" width="120">
                </el-table-column>-->
                <el-table-column prop="careerName" label="绑定职业"></el-table-column>
                <el-table-column prop="grade" label="级别"> </el-table-column>
                <el-table-column prop="certificateNumber" label="证书编号" width="200"></el-table-column>
                <el-table-column prop="auditState" label="绑定状态">
                  <template slot-scope="scope">
                    <!-- （0：待审核，1：绑定成功，2：绑定失败 -->
                    <span v-if="scope.row.auditState == 0" class="warning">待审核</span>
                    <span v-if="scope.row.auditState == 1" class="success">绑定成功</span>
                    <span v-if="scope.row.auditState == 2" class="danger">绑定失败</span>
                    <span v-if="scope.row.auditState == 3" class="success">已解绑</span>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" width="10%"></el-table-column>

                <el-table-column fixed="right" prop="auditState" label="操作">
                  <template slot-scope="scope">
                    <el-button @click.stop="mediumDialog(2, 2, scope.row)" v-if="scope.row.auditState == 1" plain size="small">解绑</el-button>
                    <el-button plain @click="unitBinding(1, scope.row, 'resetb')" v-if="scope.row.auditState == 2" size="small">重新绑定</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="单位申请职业认证" name="second">
              <el-table v-loading="load" :data="unitApplyTable" style="width: 100%">
                <el-table-column fixed prop="unitName" label="单位名称"></el-table-column>
                <el-table-column prop="userName" label="联系人" width="120"></el-table-column>
                <el-table-column prop="phone" label="联系电话" width="120"></el-table-column>
                <el-table-column prop="careerName" label="绑定职业" width="140"></el-table-column>
                <el-table-column prop="grade" label="级别" width="120"></el-table-column>
                <el-table-column prop="certificateNumber" label="证书编号" width="240"></el-table-column>
                <el-table-column prop="applyState" label="绑定状态=" width="120">
                  <template slot-scope="scope">
                    <!-- （0：申请中，1：申请成功，2：申请失败 3：未申请)-->
                    <span v-if="scope.row.applyState == 0" class="warning">申请中</span>
                    <span v-if="scope.row.applyState == 1" class="success">申请成功</span>
                    <span v-if="scope.row.applyState == 2" class="danger">申请失败</span>
                    <span v-if="scope.row.applyState == 3" class="danger">已解绑</span>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" width="160"></el-table-column>

                <el-table-column fixed="right" prop="applyState" label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button @click="bigDialog('audit', 4, scope.row)" v-if="scope.row.applyState == 0 && activeName == 'second'" plain size="small">审核</el-button>
                    <!-- <el-button plain @click="handleClick(scope.row)" v-if="scope.row.applyState == 3" size="small" >解绑</el-button > -->
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

    <!-- 我要认证 dialog -->
    <el-dialog :title="bigDialogTitle" :visible.sync="bigDialogVisible" center class="certificationDialog big-dialog" @close="cancel('certForm')">
      <!-- 新增认证 -->
      <el-form v-if="bigShowContent == 'newCert'" :model="certForm" :rules="certRules" ref="certForm" label-width="140px" class="certForm">
        <el-form-item class="bold" label="基本信息"> </el-form-item>
        <el-form-item label="认证单位" prop="belowTenantName">
          <el-select v-model="certForm.belowTenantName" filterable allow-create default-first-option placeholder="请选择" @change="selecteCertUnit">
            <el-option v-for="item in certUnitList" :key="item.id" :label="item.fullName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="体育项目" prop="itemName">
          <el-select v-model="certForm.itemName" filterable allow-create default-first-option placeholder="请选择" @change="funItems">
            <el-option v-for="item in options" :key="item.id" :label="item.itemName" :value="item.itemName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位名称" prop="careerName">
          <!-- <el-input type="text" v-model="certForm.careerName" placeholder="请输入职位名称" ></el-input> -->

          <el-select v-model="certForm.careerName" filterable allow-create default-first-option placeholder="请选择" @change="selecteCareer">
            <el-option v-for="item in optionsCareer" :key="item.id" :label="item.fullName" :value="item.fullName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="级别 " prop="grade">
          <!-- <el-input type="text" v-model="certForm.grade" placeholder="请输入级别" ></el-input> -->

          <el-select v-model="certForm.grade" filterable allow-create default-first-option placeholder="请选择" @change="selecteCareerLevel">
            <el-option v-for="item in optionsCareerLevel" :key="item.id" :label="item.fullName" :value="item.fullName"></el-option>
          </el-select>
          <span class="tips-text">( ps：先选职业名称 )</span>
        </el-form-item>
        <el-form-item label="证书名称" prop="careerCertificate">
          <el-input type="text" v-model="certForm.careerCertificate" placeholder="请输入证书名称"></el-input>
        </el-form-item>
        <el-form-item label="证书编号" prop="certificateNumber">
          <el-input type="text" v-model="certForm.certificateNumber" placeholder="请输入证书编号"></el-input>
        </el-form-item>
        <el-form-item label="获取时间" prop="getTime">
          <el-date-picker v-model="certForm.getTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="上传证书附件" prop="certificatePhoto">
          <el-upload :action="uploadImg()" :headers="uploadHeaders()" list-type="picture-card" :file-list="fileList" :on-success="handleAvatarSuccess" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="tip">上传照片格式要求，jpg、jpeg、bmp、png，不超过10m。</div>
        </el-form-item>

        <el-form-item class="bold" label="个人履历"> </el-form-item>
        <template v-for="(time, index) in certResumeList">
          <template v-if="index == 0 || certResumeList.length == 0">
            <el-form-item label="起始时间" :key="'ctime-' + index">
              <el-date-picker v-model="time.newCRtime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
              <el-button type="text" class="btn-delCertResume" @click="delCertResume(time, index)">删除</el-button>
            </el-form-item>
            <el-form-item label="履历说明" :key="'desc-' + index">
              <el-input type="textarea" v-model="time.newCRname"></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :key="'ctime-' + index">
              <el-date-picker v-model="time.newCRtime" type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              <el-button type="text" class="btn-delCertResume" @click="delCertResume(time, index)">删除</el-button>
            </el-form-item>
            <el-form-item :key="'desc-' + index">
              <el-input type="textarea" v-model="time.newCRname"></el-input>
            </el-form-item>
          </template>
        </template>

        <el-form-item>
          <el-button icon="el-icon-plus" circle @click="addCertResume"></el-button>
        </el-form-item>
      </el-form>

      <!-- 认证中 ,认证失败,认证成功 查看详情-->
      <el-scrollbar  class="u-dialog">
      <div v-if="bigShowContent == 'certStatus'" class="certification-result">
        <div class="top-tips">
          <h2 v-if="bigShowSubcont == 0">
            <span class="iconfont icon-dengdai warning"></span>
            <span class="tips-text">认证中</span>
          </h2>
          <h2 v-if="bigShowSubcont == 1">
            <span class="iconfont icon-chenggong success"></span>
            <span class="tips-text">认证成功</span>
          </h2>
          <h2 v-if="bigShowSubcont == 2">
            <span class="iconfont icon-shibai danger"></span>
            <span class="tips-text">认证失败，请重新认证</span>
          </h2>
        </div>
        <div class="error-tips" v-if="bigShowSubcont == 2 && certificateInfo.attestationRemark != ''">
          <span class="error-name">失败原因：</span>
          <p class="ql-editor" v-html="certificateInfo.attestationRemark"></p>
        </div>
        <ul class="certinfo">
          <li>
            <span class="cert-name">认证单位：</span>
            <span class="cert-value">{{ certificateInfo.belowTenantName }}</span>
          </li>

          <li>
            <span class="cert-name">职位名称：</span>
            <span class="cert-value">{{ certificateInfo.careerName }}</span>
          </li>
          <li>
            <span class="cert-name">是否公开：</span>
            <span class="cert-value">{{ certificateInfo.isopen == 0 ? "否" : "是" }}</span>
          </li>
          <template v-if="showCI">
            <li class="a-detail-title">证书信息</li>
            <li>
              <span class="cert-name">体育项目：</span>
              <span class="cert-value">{{ certificateInfo.itemName }}</span>
            </li>
            <li>
              <span class="cert-name">级别：</span>
              <span class="cert-value">{{ certificateInfo.grade }}</span>
            </li>
            <li>
              <span class="cert-name">证书名称：</span>
              <span class="cert-value">{{ certificateInfo.careerCertificate }}</span>
            </li>
            <li>
              <span class="cert-name">证书编号：</span>
              <span class="cert-value">{{ certificateInfo.certificateNumber }}</span>
            </li>
            <li>
              <span class="cert-name">获取时间：</span>
              <span class="cert-value">{{ certificateInfo.getTime }}</span>
            </li>
            <li>
              <span class="cert-name">证书附件：</span>
              <span class="cert-value" v-for="(img, index) in certificateInfoPhoto" :key="index">
                <el-image :src="loadUrl(img)"><div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div></el-image>
              </span>
            </li>
          </template>
          <!-- 基本信息 -->
          <li class="a-detail-title">基本信息</li>
          <template v-if="certificateInfo.recordInformations">
            <li>
              <span class="cert-name">姓名：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.name }}</span>
            </li>
            <li>
              <span class="cert-name">籍贯：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.natives }}</span>
            </li>
            <li>
              <span class="cert-name">性别：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.sex == 1 ? "男" : "女" }}</span>
            </li>
            <li>
              <span class="cert-name">出生日期：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.birthDate }}</span>
            </li>
            <li>
              <span class="cert-name">身份证号码：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.identityCard }}</span>
            </li>
            <li>
              <span class="cert-name">身份证住址：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.identityAddress }}</span>
            </li>
            <li>
              <span class="cert-name">民族：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.nation }}</span>
            </li>
            <li>
              <span class="cert-name">手机号码：</span>
              <span class="cert-value">{{ certificateInfo.recordInformations.phone }}</span>
            </li>
          </template>

          <!-- code -->
          <template v-if="customForm && customForm.length > 0">
            <li class="a-detail-title">绑定单位信息</li>
            <template v-for="(cfItem, index) in customForm">
              <li :key="'RECTF-' + index">
                <span class="cert-name">{{ cfItem.label }}：</span>
                <template v-if="cfItem.tagIcon == 'upload' && cfItem.defaultValue.length > 0">
                  <span v-for="(img,index) in cfItem.defaultValue" :key="index">
                    <el-image :src="img.url"></el-image>
                  </span>
                </template>
                <span v-else class="cert-value">{{ cfItem.defaultValue }}</span>
              </li>
            </template>
          </template>

          <!-- 回显履历 -->
          <!-- recordEducations 学历（学位）教育情况-->
          <template v-if=" certificateInfo.recordEducations && certificateInfo.recordEducations.length > 0">
            <li class="a-detail-title">学历（学位）教育情况</li>
            <template v-for="(reTime, index) in certificateInfo.recordEducations">
              <li class="a-sub-title" :key="'REdu--' + index"></li>

              <li :key="'renewtime-' + index">
                <span class="cert-name">起始时间：</span>
                <span class="cert-value">{{ reTime.startDate }} 至 {{ reTime.endDate }}</span>
              </li>

              <li :key="'RESG-' + index">
                <span class="cert-name">毕业院校：</span>
                <span class="cert-value">{{ reTime.schoolGraduation }}</span>
              </li>

              <li :key="'REST-' + index">
                <span class="cert-name">专业：</span
                ><span class="cert-value">{{ reTime.specialty }}</span>
              </li>
              <li :key="'RES-' + index">
                <span class="cert-name">学制（年）：</span>
                <span class="cert-value">{{ reTime.schooling }}</span>
              </li>

              <li :key="'REE-' + index">
                <span class="cert-name">学历（学位）：</span>
                <span class="cert-value">{{ reTime.education }}</span>
              </li>
              <li :key="'RERF-' + index">
                <span class="cert-name">办学形式：</span>
                <span class="cert-value">{{ reTime.runningForms }}</span>
              </li>
            </template>
          </template>

          <!-- recordPromotes 职称晋升情况 -->
          <template v-if=" certificateInfo.recordPromotes && certificateInfo.recordPromotes.length > 0">
            <li class="a-detail-title">职称晋升情况</li>
            <template v-for="(rptime, index) in certificateInfo.recordPromotes">
              <li class="a-sub-title" :key="'RP--' + index"></li>

              <li :key="'RPGD-' + index">
                <span class="cert-name">职称获得时间：</span>
                <span class="cert-value">{{ rptime.gradeDate }}</span>
              </li>

              <li :key="'RPPT-' + index">
                <span class="cert-name">等级：</span>
                <span class="cert-value">{{ rptime.professionTypeGrade }}</span>
              </li>

              <li :key="'RPAU-' + index">
                <span class="cert-name">所在单位：</span>
                <span class="cert-value">{{ rptime.affiliatedUnit }}</span>
              </li>
            </template>
          </template>

          <!-- recordPositions 岗位 -->
          <template v-if=" certificateInfo.recordPositions && certificateInfo.recordPositions.length > 0">
            <li class="a-detail-title">岗位</li>
            <template v-for="(rposttime, index) in certificateInfo.recordPositions">
              <li class="a-sub-title" :key="'RPpt--' + index"></li>

              <li :key="'RPSD-' + index">
                <span class="cert-name">岗位开始时间：</span>
                <span class="cert-value">{{ rposttime.startDate }}</span>
              </li>

              <li :key="'RPED-' + index">
                <span class="cert-name">岗位结束时间：</span>
                <span class="cert-value">{{ rposttime.endDate }}</span>
              </li>

              <li :key="'RPTU-' + index">
                <span class="cert-name">培训单位：</span>
                <span class="cert-value">{{ rposttime.trainUnit }}</span>
              </li>
              <li :key="'RPSU-' + index">
                <span class="cert-name">主办单位：</span>
                <span class="cert-value">{{ rposttime.sponsorUnit }}</span>
              </li>
              <li :key="'RPD-' + index">
                <span class="cert-name">地点：</span>
                <span class="cert-value">{{ rposttime.address }}</span>
              </li>
            </template>
          </template>

          <!-- recordPapers 发表论文-->
          <template v-if=" certificateInfo.recordPapers && certificateInfo.recordPapers.length > 0">
            <li class="a-detail-title">发表论文</li>
            <template v-for="(rpaperstime, index) in certificateInfo.recordPapers">
              <li class="a-sub-title" :key="'RPpst--' + index"></li>

              <li :key="'RPAPP-' + index">
                <span class="cert-name">发表时间：</span>
                <span class="cert-value">{{ rpaperstime.postedTime }}</span>
              </li>

              <li :key="'RPAPPT-' + index">
                <span class="cert-name">论文题目：</span>
                <span class="cert-value">{{ rpaperstime.paperTitle }}</span>
              </li>

              <li :key="'RPAPLD-' + index">
                <span class="cert-name">链接地址：</span>
                <span class="cert-value">{{ rpaperstime.linkAddress }}</span>
              </li>
              <li :key="'RPAPPN-' + index">
                <span class="cert-name">刊物名称：</span>
                <span class="cert-value">{{ rpaperstime.periodicalName }}</span>
              </li>
            </template>
          </template>

          <!-- recordWorkExperiences 工作经历 -->
          <template v-if=" certificateInfo.recordWorkExperiences && certificateInfo.recordWorkExperiences.length > 0">
            <li class="a-detail-title">工作经历</li>
            <template v-for="(rwetime, index) in certificateInfo.recordWorkExperiences">
              <li class="a-sub-title" :key="'RWErwe--' + index"></li>
              <li :key="'RWEtime-' + index">
                <span class="cert-name">起止年月：</span>
                <span class="cert-value">{{ rwetime.startDate }} 至 {{ rwetime.endDate }}</span>
              </li>

              <li :key="'RWEUN-' + index">
                <span class="cert-name">单位：</span>
                <span class="cert-value">{{ rwetime.unitName }}</span>
              </li>

              <li :key="'RWEP-' + index">
                <span class="cert-name">职务：</span>
                <span class="cert-value">{{ rwetime.position }}</span>
              </li>
            </template>
          </template>
        </ul>
      </div>
      </el-scrollbar>
      <!-- 绑定单位 -->
      <div v-if="bigShowContent == 'unit'" class="unit-bar">
        <el-form :inline="true" :model="queryUnitForm" ref="queryUnitForm" :rules="queryUnitRules" label-width="100px" class="search-bar">
          <el-form-item label="单位名称：" prop="title">
            <el-input v-model.trim="queryUnitForm.title" placeholder="请输入单位名称" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="submitUnitForm('queryUnitForm')">搜索</el-button>
            <el-button @click="resetUnitForm('queryUnitForm')">重置</el-button>
          </el-form-item>
        </el-form>

        <ul class="unit-choose" v-if="unitedList.length > 0">
          <li>已绑定单位：</li>
          <li v-for="uItem in unitedList" :key="uItem.id">
            <h3>{{ uItem.unitName }}</h3>
            <span @click="mediumDialog(2, 2, uItem)">解绑</span>
          </li>
        </ul>

        <div class="unit-list">
          <el-checkbox-group v-model="checkUnitList">
            <el-checkbox v-for="unitItem in unitList" :key="unitItem.id" :label="unitItem">{{ unitItem.fullName }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <!-- 审核 -->
      <div v-if="bigShowContent == 'audit'" class="audit">
        <ul class="certinfo" v-if="bigShowSubcont == 4">
          <li>
            <span class="cert-name">单位名称：</span>
            <span class="cert-value">{{ unitApplyInfo.unitName }}</span>
          </li>
          <li>
            <span class="cert-name">联系人：</span>
            <span class="cert-value">{{ unitApplyInfo.userName }}</span>
          </li>
          <li>
            <span class="cert-name">联系电话：</span>
            <span class="cert-value">{{ unitApplyInfo.phone }}</span>
          </li>
          <li>
            <span class="cert-name">绑定职业：</span>
            <span class="cert-value">{{ unitApplyInfo.careerName }}</span>
          </li>
          <li>
            <span class="cert-name">级别：</span>
            <span class="cert-value">{{ unitApplyInfo.grade }}</span>
          </li>
          <li>
            <span class="cert-name">证书编号：</span>
            <span class="cert-value">{{ unitApplyInfo.certificateNumber }}</span>
          </li>
        </ul>
        <el-input v-if="bigShowSubcont == 5" type="textarea" :rows="4" placeholder="请输入内容" v-model="auditText"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <template v-if="bigShowContent == 'newCert'">
          <el-button type="primary" @click="submitForm('certForm')">确 定</el-button>
        </template>
        <template v-if="bigShowContent == 'certStatus'">
          <el-button v-if="bigShowSubcont == 0" type="primary" @click="editCInfo(certificateInfo)">修改认证</el-button>
          <el-button v-if="bigShowSubcont == 1" type="primary" @click="cancel()">确 定</el-button>
          <el-button v-if="bigShowSubcont == 2" type="primary" @click="editCInfo(certificateInfo)">重新认证</el-button>
        </template>
        <template v-if="bigShowContent == 'unit'">
          <el-button type="primary" @click="submitUnit('queryUnitForm')">绑定</el-button>
        </template>
        <template v-if="bigShowContent == 'audit'">
          <el-button v-if="bigShowSubcont == 4" type="danger" @click="bigDialog('audit', 5, unitApplyInfo)">审核不通过</el-button>
          <el-button v-if="bigShowSubcont == 4" type="primary" @click="mediumDialog(3, 4)">审核通过</el-button>
          <el-button v-if="bigShowSubcont == 5" type="primary" @click="mediumDialog(4, 5)">确定</el-button>
        </template>
      </span>
    </el-dialog>

    <!-- 解绑，成为会员,审核 提示 -->
    <el-dialog :visible.sync="mediumDialogVisible" class="medium-dialog" :show-close="false" center>
      <div class="tips-cont">{{ mediumShowContent }}</div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="mediumDialogVisible = false">取 消</el-button>
        <el-button v-if="mediumShowBtn == 1" type="primary" @click="golink">成为会员</el-button>
        <el-button v-else type="primary" @click="mediumSubmit">确定</el-button>
      </span>
    </el-dialog>

    <!-- 成功与失败 -->
    <el-dialog :visible.sync="smallDialogVisible" class="small-dialog" :show-close="false">
      <div class="tips-cont">
        <span v-if="isok" class="iconfont icon-chenggong isok"></span>
        <span v-else class="iconfont isno icon-shibai"></span>
        <span class="text">{{ smallShowContent }}</span>
      </div>
    </el-dialog>

    <!-- 认证书 图片预览 logVisible -->
    <el-dialog :visible.sync="imgdialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import {
  createProfessionalApply,
  qualificationAuthentication,
  getList,
  isPublic,
  getDetail,
  bindUnit,
  unBindUnit,
  getBindUnited,
  getUnitList,
  myQueryMeBindUnit,
  unitApplyList,
  unitQualification,
  getBaseItemList,
  recordDeleteId,
  isRecordInformation,
  getCertUnitList,
} from "@/api/professional";
import myParticipationList from "@/pages/component/myParticipationList";
import ProfessionalInformation from "@/pages/user/professionalInformation";
import { dict } from "@/api/dict";

export default {
  name: "user-professional-certification",
  components: {
    myParticipationList,
    ProfessionalInformation,
  },
  data() {
    return {
      bigDialogVisible: false,
      mediumDialogVisible: false,
      smallDialogVisible: false,
      showCI: false,
      // 新增认证,详情--（认证中，认证成功，认证失败）,绑定单位 ,审核(审核,审核不通过)
      bigDialogTitle: "新增认证",
      // newCert,certStatus,unit,audit
      bigShowContent: "newCert",
      bigShowSubcont: 0,

      mediumShowContent: "",
      mediumShowBtn: 0,
      mediumMSG: "",

      smallShowContent: "",
      smallShowBtn: 0,
      smallMSG: "",

      selected: "pc",
      ispublic: false,

      imgdialogVisible: false,
      dialogImageUrl: "",
      checkUnitList: [],
      isok: false,
      queryUnitForm: {
        title: "",
      },
      queryUnitRules: {
        title: [{ message: "请输入单位名称", trigger: "change" }],
      },
      activeName: "first",
      auditText: "",
      fileList: [],
      certForm: {
        tenantId: "",
        belowTenantId: "",
        belowTenantName: "",
        itemName: "",
        items: "",
        careerName: "",
        careerName: "",
        grade: "",
        careerCertificate: "",
        certificateNumber: "",
        getTime: "",
        certificatePhoto: "",
        qualificationRecords: [],
      },
      certRules: {
        belowTenantName: [
          { required: true, message: "请选择认证单位", trigger: "change" },
        ],
        itemName: [
          { required: true, message: "请选择体育项目", trigger: "change" },
        ],
        careerName: [{ required: true, message: "职位名称", trigger: "blur" }],
        grade: [{ required: true, message: "请输入级别", trigger: "blur" }],
        careerCertificate: [
          { required: true, message: "请输入证书名称", trigger: "blur" },
        ],
        certificateNumber: [
          { required: true, message: "请输入证书编号", trigger: "blur" },
        ],
        getTime: [
          {
            type: "string",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        certificatePhoto: [
          { required: true, message: "上传证书附件", trigger: "blur" },
        ],
      },
      options: [
        {
          value: "形意拳",
          label: "形意拳",
        },
        {
          value: "八卦拳",
          label: "八卦拳",
        },
        {
          value: "通臂拳",
          label: "通臂拳",
        },
        {
          value: "陈式太极拳",
          label: "陈式太极拳",
        },
        {
          value: "长拳",
          label: "长拳",
        },
        {
          value: "咏春拳",
          label: "咏春拳",
        },
        {
          value: "南拳",
          label: "南拳",
        },
        {
          value: "少林拳",
          label: "少林拳",
        },
      ],
      userId: "",
      unitId: "",
      certId: "",
      unUnitId: "",
      unUnitItem: "",
      // 履历
      certResumeList: [
        {
          newCRname: "",
          newCRtime: [],
          // id: '000',
        },
      ],
      newCRid: 0,
      // 认证
      certificateList: [],
      certificateInfo: {},
      certificateInfoPhoto: [],
      getTime: "",
      optionsCareer: [],
      optionsCareerLevel: [],
      // 单位
      unitedList: [],
      unitList: [],
      applyId: 0,
      mybingUnitTable: [],
      unitApplyTable: [],
      unitApplyInfo: {},
      SubMenu: [
        { value: "pc", label: "我要认证" },
        // { value: "unit", label: "单位绑定管理" },
      ],
      load: true,
      load2: true,
      // 控制显示认证职业名称信息表
      pinfoShow: true,
      tenant_id: "sundo",
      cpitem: {},
      // 认证单位
      certUnitList: [],
      customForm: [],
      // 办学形式 988089a029364cd2a3582c2c638145d0
      dictRunningForms: [],
      // 教练级别 9230bc5d524541869bcffa654d552e11
      dictCoachGrade: [],
      // 裁判级别 059aece5517f4160834763cf4d971cdb
      dictRefereeGrade: [],
      // 职业名称 f6ba8b07fbae4a2daa22177df2588c4e
      dictCareerCategory: [],

      // 查询字典字段集
      dictAll: [
        {
          fname: "coachGrade",
          dname: "dictCoachGrade",
          id: "9230bc5d524541869bcffa654d552e11",
        },
        {
          fname: "refereeGrade",
          dname: "dictRefereeGrade",
          id: "059aece5517f4160834763cf4d971cdb",
        },
        //  {
        //   fname: "careerCategory",
        //   dname: "dictCareerCategory",
        //   id: "f6ba8b07fbae4a2daa22177df2588c4e",
        // },
        {
          fname: "runningForms",
          dname: "dictRunningForms",
          id: "988089a029364cd2a3582c2c638145d0",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.userId = this.user.userInfo.userId;
    this.tenant_id = window.localStorage.getItem("tenant_id") || "sundo";
    this.getLists();
  },
  methods: {
    // 获取认证列表
    getLists() {
      const self = this;
      this.load = true;
      self.$store.commit("showLoading");
      let param = {
        userId: this.userId,
        tenantId: this.tenant_id,
      };

      getList(param).then((res) => {
        if (res.code == 200) {
          self.certificateList = res.data;
        } else {
          self.$message(res.msg);
        }
        this.load = false;
        self.$store.commit("hideLoading");
      });
    },
    // 获取子组件数据
    showPI(val) {
      this.pinfoShow = val;
      this.getLists();
    },
    // 体育项目 old
    getBaseItemLists() {
      const self = this;
      getBaseItemList({ itemType: 2 }).then((res) => {
        if (res.code == 200) {
          self.options = res.data;
        } else {
          self.$message(res.msg);
        }
      });
    },
    funItems(value) {
      this.options.map((item) => {
        if (item.itemName == value) {
          this.certForm.items = item.id;
        }
      });
    },
    // 认证详情
    getDetails(item) {
      const self = this;
      self.$store.commit("showLoading");
      getDetail(null, item.id).then((res) => {
        if (res.code == 200) {
          self.certificateInfo = res.data;
          if (res.data.careerName != "工作人员") {
            self.showCI = true;
          } else {
            self.showCI = false;
          }
          self.customForm = JSON.parse(res.data.personnelData.code);
          // 回
          self.customForm.map((item) => {
            if (item.options) {
              item.options.map((oItem) => {
                if (item.defaultValue == oItem.value) {
                  item.defaultValue = oItem.name;
                }
              });
            }
          });
          // 下拉回显
          self.showDicts();
          setTimeout(() => {
            self.dictFilter(res.data);
          }, 1000);

          // 详情图片处理
          self.certificateInfoPhoto =
            self.certificateInfo.certificatePhoto.split(",");
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    // 是否公开
    changePublic(item) {
      const self = this;
      let params = {
        id: item.id,
        isopen: item.isopen,
      };
      isPublic(params).then((res) => {
        if (res.code == 200) {
          self.$message(res.msg);
        } else {
          self.$message(res.msg);
        }
      });
    },
    // 大的弹出层
    bigDialog(cType, sType, c_item) {
      this.bigDialogVisible = true;
      this.bigShowContent = cType;
      this.bigShowSubcont = sType;
      this.bigDialogTitle =
        cType == "newCert"
          ? "新增认证"
          : cType == "certStatus"
          ? "详情"
          : cType == "unit"
          ? "绑定单位"
          : cType == "audit" && sType == 4
          ? "审核"
          : "审核不通过";
      if (cType == "certStatus") {
        this.getDetails(c_item);
      } else if (c_item != undefined && cType == "newCert") {
        this.certForm = this.certificateInfo;
        this.getCertUnitLists();
        this.resetCertForm();
        this.bigDialogTitle = "重新增认证";
        this.getDict();
      } else if (cType == "audit") {
        this.unitApplyInfo = c_item;
      } else if (cType == "newCert") {
        this.getBaseItemLists();
        this.getCertUnitLists();
        this.getDict();
      }
    },
    // 中的弹出层
    mediumDialog(cType, sType, item) {
      this.unUnitItem = item;

      this.bigDialogVisible = false;
      this.mediumDialogVisible = true;
      this.mediumShowBtn = sType;
      this.mediumShowContent =
        cType == 1
          ? this.mediumMSG
          : cType == 2
          ? "确定取消绑定？"
          : cType == 3
          ? "确定审核通过？"
          : "确定审核不通过？";
      // if(cType == 1){}
    },
    // 确认解绑
    mediumSubmit() {
      if (this.mediumShowBtn == 4 || this.mediumShowBtn == 5) {
        this.unitQualifications();
      } else if (this.mediumShowBtn == 2) {
        this.unBindUnits();
      }
    },
    // 小的弹出层
    smallDialog(cType, sType) {
      const self = this;
      this.bigDialogVisible = false;
      this.mediumDialogVisible = false;
      this.smallDialogVisible = true;
      this.mediumShowBtn = sType;
      this.smallShowContent =
        cType == 1
          ? this.smallMSG
          : cType == 2
          ? "解绑成功"
          : cType == 3
          ? "成功"
          : "失败";

      setTimeout(() => {
        self.smallDialogVisible = false;
      }, 3000);
    },
    // 添加履历
    addCertResume() {
      this.newCRid += 1;
      let item = {
        newCRname: "",
        newCRtime: [],
        // id:
        //   this.newCRid < 10
        //     ? "00" + this.newCRid
        //     : this.newCRid < 100
        //     ? "0" + this.newCRid
        //     : "" + this.newCRid,
      };
      this.certResumeList.push(item);
    },
    // 删除履历-- (远程)
    recordDeleteIds(id) {
      recordDeleteId(null, id).then((res) => {
        if (res.code == 200 && res.data) {
          this.$message(res.msg);
        } else {
          this.$message(res.msg);
          return false;
        }
      });
    },
    // 删除履历-- (本地)
    delCertResume(citem, i) {
      if (citem.id) {
        this.recordDeleteIds(citem.id);
      }
      this.certResumeList.map((item, index) => {
        if (
          (i == index && i != 0) ||
          (i == 0 && index == 0 && this.certResumeList.length != 1)
        ) {
          this.certResumeList.splice(index, 1);
        } else if (i == 0 && this.certResumeList.length == 1) {
          this.certResumeList = [
            {
              newCRname: "",
              newCRtime: [],
            },
          ];
        }
      });
    },
    // 创建认证项目 / 修改
    submitForm(formName) {
      const self = this;
      self.certForm.tenantId = self.tenant_id;
      self.certForm.qualificationRecords = [];
      self.certResumeList.map((item) => {
        let qid = self.certResumeList[0].qid;
        let _item = {
          startTime: item.newCRtime[0] || "",
          endTime: item.newCRtime[1] || "",
          recordText: item.newCRname,
          id: item.id || null,
          qid,
        };

        if (_item.recordText && _item.startTime != "") {
          self.certForm.qualificationRecords.push(_item);
        }
      });
      self.$refs[formName].validate((valid) => {
        if (valid) {
          self.$store.commit("showLoading");
          if (this.bigDialogTitle == "新增认证") {
            // 新增认证
            createProfessionalApply(self.certForm).then((res) => {
              if (res.code == 200) {
                self.cancel(formName);
                self.$message(res.msg);
                self.getLists();
                self.resetForm(formName);
              }
              self.$store.commit("hideLoading");
            });
          } else {
            // 重新认证
            qualificationAuthentication(self.certForm).then((res) => {
              if (res.code == 200) {
                self.cancel(formName);
                self.$message(res.msg);
                self.getLists();
                self.resetForm(formName);
              }
              self.$store.commit("hideLoading");
            });
          }
        } else {
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.reset();
      // this.$nextTick(() => {
      //   this.$refs[formName].resetFields();
      // });
      // if (this.$refs[formName] !== undefined) {
      //   this.$refs[formName].resetFields();
      // }
    },
    // 重置
    reset() {
      this.bigShowContent = "";
      this.certForm = {
        tenantId: "",
        belowTenantId: "",
        belowTenantName: "",
        itemName: "",
        careerName: "",
        grade: "",
        careerCertificate: "",
        certificateNumber: "",
        getTime: "",
        certificatePhoto: "",
        qualificationRecords: [],
      };
      this.certResumeList = [
        {
          newCRname: "",
          newCRtime: [],
          id: null,
          qid: null,
        },
      ];
      this.fileList = [];
    },
    // 回显认证
    resetCertForm() {
      // 图片回显
      let imgUrl = this.certificateInfo.certificatePhoto.split(",");
      let qualificationRecords = this.certificateInfo.qualificationRecords;
      this.certResumeList = [];
      imgUrl.map((item) => {
        let _imgUrl = {
          name: item,
          url: this.loadUrl(item),
        };
        this.fileList.push(_imgUrl);
      });
      if (qualificationRecords.length == 0) {
        this.certResumeList = [
          {
            newCRname: "",
            newCRtime: [],
          },
        ];
      } else {
        qualificationRecords.map((item) => {
          let _item = {
            newCRtime: [item.startTime, item.endTime] || [],
            newCRname: item.recordText || "",
            id: item.id || null,
            qid: item.qid || this.certId || null,
          };
          this.certResumeList.push(_item);
        });
      }
    },
    // 图片处理 -- 移除
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 图片处理 -- 显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgdialogVisible = true;
    },
    // 图片处理 -- 添加
    handleAvatarSuccess(res, file) {
      let name = res.data.url;
      this.certForm.certificatePhoto = "";
      let imgUrl = {
        name,
        url: this.loadUrl(res.data.url),
      };
      this.fileList.push(imgUrl);
      this.fileList.map((item) => {
        this.certForm.certificatePhoto += item.name + ",";
      });
      this.certForm.certificatePhoto = this.certForm.certificatePhoto.substring(
        0,
        this.certForm.certificatePhoto.lastIndexOf(",")
      );
    },
    // 获取绑定单位列表
    getUnitLists() {
      const self = this;
      let values = self.queryUnitForm.title;
      getUnitList(null, values, self.tenant_id).then((res) => {
        if (res.code == 200) {
          self.unitList = res.data;
        } else {
          self.$message(res.msg);
        }
      });
    },
    // 获取已绑定单位
    getBindUniteds(id) {
      const self = this;
      getBindUnited(null, id).then((res) => {
        if (res.code == 200) {
          self.unitedList = res.data;
        } else {
          self.$message(res.msg);
        }
      });
    },
    // 绑定单位
    unitBinding(status, c_item, resetb) {
      this.bigDialog("unit", status);
      // resetb:'resetb'(列表：重新绑定)
      this.certId = resetb == "resetb" ? c_item.qid : c_item.id;
      this.getBindUniteds(c_item.id);
      // this.getUnitLists();
    },
    // 修改职业名称信息
    editCInfo(certificateInfo) {
      this.pinfoShow = false;
      this.bigDialogVisible = false;
      this.cpitem = certificateInfo;
    },
    // 新建职业名称信息
    newCInfo() {
      this.pinfoShow = false;
      this.cpitem = { id: "" };
    },
    // 关闭重置表单
    cancel(formName) {
      this.bigDialogVisible = false;
      if (this.bigShowContent == "newCert") {
        // this.resetForm("certForm");
        this.reset();
      }
    },
    // 绑定单位搜索:(获取单位列表)
    submitUnitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getUnitLists();
        } else {
          return false;
        }
      });
    },
    // 重置单位列表搜索
    resetUnitForm(formName) {
      this.$set(this.queryUnitForm, "title", "");
      this.unitList = [];
    },
    // 确定绑定单位
    submitUnit(formName) {
      const self = this;
      let params = [];
      self.checkUnitList.map((item) => {
        let uItem = {
          unitId: item.id,
          unitName: item.fullName,
          userQid: self.userId,
          qid: self.certId,
        };
        params.push(uItem);
      });

      bindUnit(params).then((res) => {
        if (res.code == 200) {
          this.smallMSG = res.msg;
          this.isok = true;
          this.smallDialog(1, 1);
        } else if (res.code == 400) {
          self.$message(res.msg);
          this.smallMSG = res.msg;
          this.smallDialog(1, 1);
        } else {
          self.$message(res.msg);
          self.applyId = res.data;
          this.mediumMSG = res.msg;
          self.mediumDialog(1, 1);
        }
        self.checkUnitList = [];
      });
    },
    // 解绑单位
    unBindUnits() {
      const self = this;
      // self.unUnitItem
      let params = {
        id: self.unUnitItem.id,
        buid: self.unUnitItem.buid,
        unitId: self.unUnitItem.unitId,
      };

      unBindUnit(params).then((res) => {
        if (res.code == 200) {
          this.smallMSG = res.msg;
          this.isok = true;

          this.smallDialog(this.mediumShowBtn, 1);
          // this.smallDialog(1, 1);
        } else if (res.code == 400) {
          // self.$message(res.msg);
          // this.smallMSG = res.msg;
          // this.smallDialog(1, 1);
        } else {
          // self.$message(res.msg);
          // self.applyId = res.data;
          // this.mediumMSG = res.msg;
          // self.mediumDialog(1, 1);
        }
        self.checkUnitList = [];
      });
    },
    handleClick(row) {
      // 解绑
      // this.mediumDialog(2, 2, row.id)
    },
    // 成为会员
    golink() {
      this.mediumDialogVisible = false;
      this.$router.push(`partners-apply/${this.applyId}`);
    },
    // 我的绑定单位
    myQueryMeBindUnits() {
      this.load = true;
      myQueryMeBindUnit({
        current: 1,
        size: 100,
      }).then((res) => {
        if (res.code == 200) {
          this.mybingUnitTable = res.data.list;
        } else {
          this.$message(res.msg);
        }
        this.load = false;
      });
    },
    // 单位向我申请列表
    unitApplyLists() {
      this.load = true;
      unitApplyList({
        current: 1,
        size: 100,
      }).then((res) => {
        if (res.code == 200) {
          this.unitApplyTable = res.data.list;
        } else {
          this.$message(res.msg);
        }
        this.load = false;
      });
    },
    // 审核
    unitQualifications() {
      this.unitApplyInfo.applyState = this.mediumShowBtn == 4 ? 1 : 2;
      (this.unitApplyInfo.remark =
        this.mediumShowBtn == 4 ? "" : this.auditText),
        unitQualification(this.unitApplyInfo).then((res) => {
          if (res.code == 200) {
            this.smallDialog(3);
            this.isok = true;
          } else {
            this.smallDialog(4);
            this.$message(res.msg);
            this.isok = false;
          }
          this.auditText = "";
          this.myQueryMeBindUnits();
          this.unitApplyLists();
        });
    },
    // sideSubMenu
    sideSubMenu(item) {
      this.selected = item.value;
      if (item.value == "pc") {
        this.pinfoShow = true;
        this.getLists();
      } else {
        this.myQueryMeBindUnits();
      }
    },
    tabhandleClicks() {
      if (this.activeName == "first") {
        this.myQueryMeBindUnits();
      } else {
        this.unitApplyLists();
      }
    },
    // 字典查询 --- 职业认证:职业名称
    getDict() {
      dict("f6ba8b07fbae4a2daa22177df2588c4e", null).then((res) => {
        if (res.code == 200 && res.data) {
          this.optionsCareer = res.data.list;
        }
      });
    },
    // 选择职业
    selecteCareer(value) {
      this.optionsCareer.map((item) => {
        if (item.fullName == value) {
          this.certForm.career = item.fullName;
          this.getDictLevel(item);
        }
      });
    },
    // 字典查询 --- 职业认证:职业等级（级别）
    getDictLevel(item) {
      let _id = item.enCode;
      this.certForm.grade = "";
      dict(_id, null).then((res) => {
        if (res.code == 200 && res.data) {
          this.optionsCareerLevel = res.data.list;
        }
      });
    },
    // 选择职业
    selecteCareerLevel(value) {
      this.optionsCareerLevel.map((item) => {
        if (item.fullName == value) {
          this.certForm.grade = item.fullName;
        }
      });
    },
    // 获取可认证单位列表
    getCertUnitLists() {
      const self = this;
      let id = self.tenant_id;
      getCertUnitList(null, id).then((res) => {
        self.certUnitList = res;
      });
    },
    // 选择可认证单位
    selecteCertUnit(id) {
      this.certUnitList.map((item) => {
        if (item.id == id) {
          this.certForm.belowTenantId = item.id;
          this.certForm.belowTenantName = item.fullName;
        }
      });
    },
    // 字典查询
    getDict(fName, id) {
      dict(id, null).then((res) => {
        if (res.code == 200 && res.data) {
          this[fName] = res.data.list;
        }
      });
    },

    // 回显字典函数
    showDicts() {
      const self = this;
      self.dictAll.map((dItem) => {
        self.getDict(dItem.dname, dItem.id);
      });
    },
    // 过滤字典
    dictFilter(list) {
      const self = this;
      // 办学形式
      self.dictRunningForms.map((item) => {
        list.recordEducations.map((rItem) => {
          if (item.enCode == rItem.runningForms) {
            rItem.runningForms = item.fullName;
          }
        });
      });
      let cName = list.careerName;
      if (cName == "教练员") {
        self.dictCoachGrade.map((item) => {
          list.recordPromotes.map((rItem) => {
            if (item.enCode == rItem.professionTypeGrade) {
              rItem.professionTypeGrade = item.fullName;
            }
          });
        });
      } else if (cName == "裁判员") {
        self.dictRefereeGrade.map((item) => {
          list.recordPromotes.map((rItem) => {
            if (item.enCode == rItem.professionTypeGrade) {
              rItem.professionTypeGrade = item.fullName;
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.a-detail-title {
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
  border-top: 1px solid #ececec;
}

.a-sub-title {
  border-top: 1px solid #f7f5f5;
}

.btn-certification {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 20px;
}
.pc-cont {
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  width: 100%;
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }
  .el-tabs {
    overflow: hidden;
    width: 100%;
  }

  .card {
    position: relative;
    width: 378px;
    height: 174px;
    padding: 20px;
    margin: 0 20px 20px 0;
    border-top: 6px solid #638bd6;
    background: #ffffff url("../../assets/image/card-star-bg.png") right top
      no-repeat;
    box-shadow: 0px 0px 20px 0px rgba(154, 154, 154, 0.14);
    border-radius: 6px;
    .btn-unit {
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: 80px;
      text-align: center;
      padding: 0;
      height: 30px;
      line-height: 30px;
      border-color: #1e50ae;
      color: #1e50ae;
    }
    .btn-look {
      position: absolute;
      right: 110px;
      bottom: 20px;
      width: 110px;
      text-align: center;
      padding: 0;
      height: 30px;
      line-height: 30px;
      border-color: #1e50ae;
      color: #1e50ae;
    }
    h2 {
      height: 18px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 23px;
    }
    .card-info {
      font-size: 14px;
      line-height: 15px;
      color: #999999;
      li {
        padding-bottom: 18px;
      }
      .name {
      }
      .value {
      }

      /deep/ .el-switch__label {
        color: #c8c8c8;
      }
      /deep/ .el-switch__label.is-active {
        color: #1e50ae;
      }
    }
  }
}
.certificationDialog {
  .bold {
    font-weight: bold;
    color: #333333;
    /deep/ .el-form-item__label {
      color: #333333;
    }
  }
  /deep/ .el-input__inner {
    color: #555;
  }
  /deep/ .el-form-item__label {
    text-align: left;
    color: #555;
  }
  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
  /deep/ .el-checkbox__label {
    color: #555;
  }
}
.certification-result {
  .top-tips {
    h2 {
      display: flex;
      align-items: center;
    }
    .iconfont {
      font-size: 44px;
    }
    .tips-text {
      padding-left: 14px;
      font-size: 24px;
      color: #333333;
    }
  }
  .error-tips {
    position: relative;
    background: #fff8f8;
    padding: 20px 10px 20px 90px;
    margin-top: 30px;
    line-height: 28px;
    color: #ff3f35;
    .error-name {
      position: absolute;
      left: 10px;
      top: 20px;
    }
  }
}

.certinfo {
  position: relative;
  padding-top: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  li {
    padding-bottom: 23px;
    display: flex;
    .cert-name {
      flex-shrink: 0;
      text-align: right;
      display: inline-block;
      width: 120px;
      padding-right: 8px;
    }
    .cert-value {
      flex: 1;
      color: #777;
    }
    .el-image {
      width: 152px;
      height: 90px;
    }
  }
}

.unit-bar {
  .search-bar {
    padding-top: 15px;
  }
  .unit-choose {
    background: #f4f8ff;
    border-radius: 4px;
    padding: 12px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #1e50ae;
      line-height: 20px;
      padding-bottom: 10px;
      h3 {
        font-size: 14px;
        font-weight: 400;
      }
      span {
        color: #ff3f35;
        cursor: pointer;
      }
    }
  }
  .unit-list {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #555;
    label {
      display: block;
      padding-bottom: 14px;
    }
  }
}

.medium-dialog {
  .tips-cont {
    // padding: 30px 0;
    color: #333333;
    font-size: 14px;
    height: 95px;
  }

  /deep/ .el-dialog {
    width: 418px;
    height: 225px;
  }
}

.small-dialog {
  .tips-cont {
    height: 100%;
    .text {
      line-height: 24px;
    }
    .iconfont {
      font-size: 35px;
      padding-right: 10px;
      &.isok {
        color: #5bc001;
      }
      &.isno {
        color: #ff3f35;
      }
    }
  }
  /deep/ .el-dialog {
    width: 250px;
    height: 100px;
  }
}
.small-dialog,
.medium-dialog {
  .tips-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/ .el-dialog {
    background: #ffffff;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333333;
  }
}

.user-content {
  .tip {
    color: #999;
    font-size: 12px;
  }
  .bold {
    height: 54px;
    h2 {
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 30px;
      border-bottom: 1px solid #ececec;
    }
  }
  .contain {
    display: flex;
    margin-top: 22px;
    .sideBar {
      width: 160px;
      flex-shrink: 0;
      border-right: 1px solid #ececec;
      min-height: 400px;
      height: calc(100vh - 156px);
      ul {
        padding-top: 6px;
        li {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: #555555;
          cursor: pointer;

          &.on {
            color: #1e50ae;
          }
        }
      }
    }
  }
}

// 履历
.certResumeBox {
  margin: 0px 4% 16px;
  width: 92%;
  padding: 10px 20px;
  box-sizing: border-box;
  background: #fff;
  border: solid 2px #fff;
  border-radius: 32px;
  box-shadow: -6px 6px 16px #eff0f5;
  .certResume-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #646566;

    .certResume-btn {
      font-size: 32px;
      &.fz12 {
        font-size: 12px;
      }
    }
  }
  .certResume-list {
    .certResume-item {
      position: relative;
      margin-top: 10px;
      padding-top: 10px;
      border-top: solid 1px #dedede;

      .van-field__label {
        margin-right: 0;
      }
      .crItem {
        color: #646566;
        font-size: 14px;
        display: flex;
        margin: 0 27px 6px 0;
        .cr-title {
          width: 44px;
          padding-left: 16px;
          flex-shrink: 0;
          line-height: 38px;
        }
        .cr-val {
          flex: 1;
          padding: 6px 10px;
          line-height: 24px;
          border: solid 1px #dedede;
          white-space: normal;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
      .certResume-btn {
        position: absolute;
        top: 32px;
        right: -10px;
        font-size: 32px;
        color: #fb7f35;
        &.fz12 {
          font-size: 12px;
        }
      }
      .van-button {
        position: absolute;
        top: 16px;
        right: -6px;
        line-height: 24px;
        width: 24px;
        padding: 0;
        height: 24px;
      }
      .van-cell {
        margin: 0 0 6px 0;
        padding: 0;
        background: #fff;
        border: solid 0 #fff;
        border-radius: 0;
        box-shadow: none;
        .van-cell__title {
          width: 60px;
          padding-left: 16px;
          flex-shrink: 0;
          line-height: 38px;
        }
        .van-cell__value {
          flex: 4;
          padding: 6px 10px;
          border: solid 1px #dedede;
        }
      }
    }
  }
}
.btn-delCertResume {
  padding-left: 16px;
  cursor: pointer;
}

.table_bind {
  /deep/ th {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.tips-text {
  padding: 0 10px;
  color: #999;
  font-size: 13px;
}
</style>
